import { Button, FormControl, IconButton, Input, InputAdornment, InputLabel } from '@material-ui/core'
import { AccountCircleOutlined, Lock, Visibility, VisibilityOff } from '@material-ui/icons'
import React, { useState, useEffect, useRef } from 'react'
import { MenuItem, Select } from '@material-ui/core'
import Axios from 'axios';
import { useNavigate } from "react-router-dom";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import helloCircle from '../../../Assets/helloCircle.json'
import { Typography } from '@material-ui/core';
import NavBar from '../../NavBar/NavBar';
import validateEmail from '../validations'
import {Store }from 'react-notifications-component'


export default function BrokerSignin() {
    const [partnerName, setpartnerName] = useState('');
    const [partnererror, setpartnererror] = useState(false)
    const [showPassword, setshowPassword] = useState(false)
    const [password, setpassword] = useState('')
    const [passworderror, setpassworderror] = useState(false)
    const [username, setusername] = useState('')
    const [usernameError, setusernameError] = useState(false)
    const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };
    const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChange = (event) => {
    setpartnerName(event.target.value);
  };
  const container = useRef(null);
    const onChange=()=>{}
 const checkvalidation=()=> {
      if(password===''){
          setpassworderror(true)
      }
      else {
          setpassworderror(false)
      }
      if(username===''){
          setusernameError(true)
      }
      else {
           setusernameError(false)
      }
      if(partnerName===''){
          setpartnererror(true)
      }
      else {
          setpartnererror(false)
      }
 }
  useEffect( ()=>{

    lottie.loadAnimation({
        container: container.current,
        renderer:'svg',
        loop:true,
        autoplay:true,
        animationData:helloCircle
    })
    
    },[])
useEffect(()=>{
        checkvalidation();
       
    
    },[partnerName,username,password])

    const onSignIn=()=>{

        Store.addNotification({
  title: "Feature Coming Soon.....!!!",
  message: "Sorry for the inconvenience ",
  type: "info",
  insert: "bottom",
  container: "bottom-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true
  }
        })
    }
  return (
  <div style={{height:'100vh'}}>
      <NavBar/>
      <div  className=' pt-5'  style={{background:'#f5f5f5',height:'100%'}}>
          <br></br> 
    <div className='row  ' style={{height:'fit-content'}} >
        <div className='col-xs-12 col-sm-6 col-md-6 mx-auto' >
            <div  className="card shadow   mt-4 ">
            <div className="">

            <div className='lead p-2 text-center' style={{background:'purple'}}>
                                      <Typography style={{color:'white',fontSize:'18px'}}>Sales Agents</Typography>
                                      <p style={{fontSize:'14px', color:'white'}}>(Please provide only accurate and no later than 3 months information)</p>
            </div>
             
              <div className="mx-auto mt-3" ref={container}  style={{ width:'5rem'}}></div>
              
              <div className='container px-5' >
              <div>
              <FormControl fullWidth={true}  >
                                                <InputLabel id="-select-label">Select Partner</InputLabel>
                                                <Select
                                                    labelId="select-label"
                                                    id="demo-simple-select"
                                                    error={partnererror}
                                                    value={partnerName}
                                                    label="Select partner "
                                                    onChange={handleChange}
                                                   
                                                >
                                                    <MenuItem value={'Constantia'}>Constantia</MenuItem>
                                                    
                                                </Select>
                                                </FormControl>
              </div>
              <div className="username">
                  <FormControl sx={{ m: 2, width: '100%' }} margin='normal' fullWidth={true} variant="contained">
                      <InputLabel htmlFor="outlined-adornment-username">Username</InputLabel>
                      <Input id="outlined-adornment-username"
                      type={'text'}
                      error={usernameError}
                      required
                      value={username}
                      onChange={(e)=>setusername(e.target.value)}
                      startAdornment={<InputAdornment position="start">
                          <AccountCircleOutlined />
                          </InputAdornment>
                      }
                      label="Username"/>
                  </FormControl>
              </div>
              <div className="password mt-3 text-center">
                  <FormControl sx={{ m: 1, width: '25ch' }} fullWidth={true}  variant="contained">
                      <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                      <Input id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      error={passworderror}
                      value={password}
                      onChange={(e)=>setpassword(e.target.value)}
                      startAdornment={<InputAdornment position="start">
                          <Lock />
                          </InputAdornment>
                      }
                      
                      endAdornment={
                      <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end" >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                      </InputAdornment>
                      } label="Password"/>
                  </FormControl>
              </div>
              <div className="text-center mt-3 mb-3">
                  <Button variant='contained' color='primary' onClick={()=>onSignIn()}>Sign in</Button>
              </div>
              </div>
          </div>
            </div>
        </div>
    </div>
   </div>
</div>
    
  )
}
