import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import NavBar from "./Components/NavBar/NavBar";
import DanMember from "./Assets/DanMember.JPG";
import SitMember from "./Assets/SitsabaMember.JPG";
import TebohoMember from "./Assets/TebohoMember.JPG";
import GordinMember from "./Assets/GordinMember.JPG";
import GroupsIcon from "@mui/icons-material/Groups";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Footer from "./Components/HomePage/Sections/Footer/Footer";

export default function ExecutiveTeam() {
  let danQualifications =
    "BA (University of Durban-Westville), BB&A(Hons)," +
    " MBA (University of Stellenbosch), FIISA, RE5";

  let danExperience =
    "27 years in the insurance industry in various roles, including," +
    "Portfolio Manager at an investment bank, Chief Risk Officer at" +
    "SAA, Managing Director at Westminster Brokers, Sales" +
    "Managing Director of 8 Countries in Africa in Marsh, Executive" +
    "GM in life and non-life business in Hollard and Absa Insurance." +
    "Experience in corporate, commercial, non-life and life insurance" +
    "products.";

  let sitsabaQualifications =
    "BA (HMS) (University of Western Cape), Dip in" +
    " Business Management (Damelin)";

  let sitsabaExperience =
    "17 years insurance experience in personal lines, commercial" +
    "  lines and corporates in South Africa and 7 other African" +
    "countries. Sitsaba headed up the Marsh Africa sales team for 7" +
    "years. He was responsible for the sales strategy and operations" +
    " for 7 countries in Africa.";

  let tebohoQualificationns =
    " BSc (Mathematics and Computer Science)" +
    "  Expected completion - 2022" +
    "  RE05 Regulatory Examination: Representatives in" +
    " all Categories of FSPs" +
    " RE01 KI Level 1: Cat I, II, IIA, III and IV Certified as" +
    " a Key Individual under the FAIS Act;";

  let tebohoExperience =
    "Over 11 years, Teboho worked in the Hollard Life Business as" +
    " Business Enablement Manager, and as the responsible Key" +
    "Individual for the business. Teboho was part of the team that" +
    "  developed and launched over 200 national branches in just over" +
    " 3 years. The team was responsible for crafting and building a" +
    " fully integrated insurance business that generated over R500" +
    " million TVA (Total Value Add) and supported international" +
    "  operations in 5 countries. ";

  let gordinQualification =
    "Bachelor of Electronics & Hons (Zim), Master of" +
    " Information Technology (Univ. of Pretoria)," +
    " Executive Management Certificate in Business" +
    " strategy (University of `la Verne, USA) MBA (Wits)";

  let gordinExperience =
    "Godden Ndoro, as part of our executive team, will help us" +
    " navigate the Insure-tech space. Godden is an innovative, goaloriented" +
    " technology leader with 17 years’ experience in" +
    " designing, building, and supporting complex technology for" +
    " organisations in financial services, mass media and the" +
    " manufacturing sector.";

  function createData(name, jobtittle, qualifications, experience) {
    return { name, jobtittle, qualifications, experience };
  }

  const rows = [
    createData("Dan Moeti", "CEO", danQualifications, danExperience),
    createData(
      "Sitsaba Bekwa",
      "Executive Chairman",
      sitsabaQualifications,
      sitsabaExperience
    ),
    createData(
      "Teboho Koenane",
      "Head of Operations",
      tebohoQualificationns,
      tebohoExperience
    ),
    createData(
      "Godden Ndoro",
      "Head of IT",
      gordinQualification,
      gordinExperience
    ),
  ];

  return (
    <div>
      <NavBar />

      <div
        className="row pt-5"
        style={{
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(96,9,121,1) 56%, rgba(0,212,255,1) 100%)",
        }}
      >
        <div className="col-xs-12 col-sm-12 col-md-12 p-4">
          <div className="p-5">
            <div className="card shadow p-5 text-center">
              <Typography
                style={{
                  fontSize: "1.5rem",
                  color: "purple",
                  overflowWrap: "normal",
                }}
              >
                Executive Management Team{" "}
                <GroupsIcon style={{ fontSize: "1.6rem" }} />
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 ">
          <Typography
            className="p-2"
            style={{
              backgroundColor: "purple",
              color: "white",
              fontSize: "1.2rem",
              textAlign: "center",
            }}
          >
            Meet Our Executive Management Team
          </Typography>
        </div>

        <div className="card px-5  " style={{ backgroundColor: "#f2f2f2" }}>
          <div className=" mt-4 card shadow">
            <div className="p-3">
              <Typography>
                <b>Our executive management team</b> is committed to seeing that
                Arella’s mission and values are carried out. With over 70 years
                of experience between them, our management team efficiently
                provides expert knowledge in key areas, hence ability to provide
                the best tailored insurance solutions for all clients.
              </Typography>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-xs-12 col-sm-3 col-md-3">
              <div className="card shadow h-100 p-3">
                <img
                  className="img-fluid mx-auto"
                  src={DanMember}
                  alt=""
                  width="150"
                  height="300"
                />
                <Typography className="text-center">
                  <b>
                    Dan Moeti<br></br>
                    Chief Executive Officer
                  </b>
                </Typography>
              </div>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-3">
              <div className="card shadow h-100 p-3">
                <img
                  className="img-fluid mx-auto"
                  src={SitMember}
                  alt=""
                  width="150"
                  height="300"
                />
                <Typography className="text-center">
                  <b>
                    Sitsaba Bekwa <br />
                    Executive Chairman
                  </b>
                </Typography>
              </div>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-3">
              <div className="card shadow h-100 p-3">
                <img
                  className="img-fluid mx-auto"
                  src={TebohoMember}
                  alt=""
                  width="150"
                  height="300"
                />

                <Typography className=" mt-3 text-center">
                  <b>
                    Teboho Koenane
                    <br />
                    Head of Operations
                  </b>
                </Typography>
              </div>
            </div>
            <div className="col-xs-12 col-sm-3 col-md-3">
              <div className="card shadow h-100 p-3">
                <img
                  className="img-fluid mx-auto"
                  src={GordinMember}
                  alt=""
                  width="150"
                  height="300"
                />
                <Typography className="text-center">
                  <b>
                    Godden Ndoro
                    <br />
                    Head of IT
                  </b>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row ">
        <div className="col-xs-12 col-sm-12 col-md-12 p-5">
          <Typography
            className="p-2"
            style={{
              backgroundColor: "purple",
              color: "white",
              fontSize: "24px",
            }}
          >
            More About the Executive Management Team
          </Typography>

          <div className="mt-3">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>FULLNAME</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>JOB TITLE</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>QUALIFICATIONS</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>EXPERIENCE AND EXPERTISE</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.jobtittle}</TableCell>
                      <TableCell align="left">{row.qualifications}</TableCell>
                      <TableCell align="left">{row.experience}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
