import React from "react";

import Typography from "@mui/material/Typography";
import NavBar from "./Components/NavBar/NavBar";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";

import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import BookIcon from "@mui/icons-material/Book";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import Footer from "./Components/HomePage/Sections/Footer/Footer";

import DanImage from "./Assets/Dan.JPG";
import SetsabaImage from "./Assets/Setsaba.JPG";
import DanCompanyImage from "./Assets/DanCompanyImage.JPG";
import SitsabaCompanyImage from "./Assets/SitsabaCompanyImage.JPG";
import AboutUsImage from "./Assets/AboutUsImage.JPG";
import Absa_logo from "./Assets/Absa_logo.png";
import pick_n_pay_logo from "./Assets/pick_n_pay_logo.png";
import psg_logo from "./Assets/psg_logo.png";
import AlexanderForbes_logo from "./Assets/AlexanderForbes_logo.png";
import Hollard_logo from "./Assets/Hollard_logo.jpg";
import marsh_log from "./Assets/marsh_logo.png";
import south_african_airways_airline_logo from "./Assets/south_african_airways_airline_logo.png";
import InfoIcon from "@mui/icons-material/Info";
export default function aboutUs() {
  const Settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const Settings2 = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <div>
      <NavBar />
      <div className="row pt-5">
        <div
          className="col-xs-12 col-sm-12 col-md-12 p-4"
          style={{
            background:
              "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(96,9,121,1) 56%, rgba(0,212,255,1) 100%)",
          }}
        >
          <div className="p-5">
            <div className="card shadow p-5 text-center">
              <Typography
                style={{
                  fontSize: "23px",
                  color: "purple",
                  overflowWrap: "normal",
                }}
              >
                About Arella <InfoIcon style={{ fontSize: "45px" }} />
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 px-5 pb-3 ">
          <div className="shadow px-3 py-1">
            <Typography
              className="mt-2 p-3 "
              style={{ color: "black", fontSize: "20px" }}
            >
              {" "}
              <b>Arella</b> is a start-up underwriting management agency (UMA)
              currently authorised to provide funeral insurance products across
              numerous consumer and business segments.
            </Typography>

            <div className="p-1">
              <Typography style={{ color: "black" }}>
                <ul className="p-3" style={{ color: "black" }}>
                  <li>
                    {" "}
                    At Arella, we strive to provide low-cost insurance products
                    and value added benefits through approved intermediaries and
                    partners.
                  </li>
                  <li>
                    Our products and benefits are tailored for the low-income
                    markets both in the formal and informal sectors.
                  </li>
                  <li>
                    Through strategic partnerships, we aim to provide insurance
                    solutions and value-added benefits that are advantageous to
                    all parties involved.
                  </li>
                </ul>
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 py-2 px-5">
          <Typography
            className="p-2 text-center"
            style={{
              backgroundColor: "purple",
              color: "white",
              fontSize: "20px",
            }}
          >
            History of Arella
          </Typography>{" "}
        </div>
      </div>

      <div className="row px-5">
        <div className="col-xs-12 col-sm-4 col-md-4 px-2 py-2">
          <Timeline position="alternate">
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "3rem 0" }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                10th August 2019
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="secondary">
                  <AppRegistrationIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "20px", px: 2 }}>
                <Typography variant="p" component="span">
                  Company Registration
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "3rem 0" }}
                variant="body2"
                color="text.secondary"
              >
                20th December 2020
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="secondary" variant="outlined">
                  <AccountBalanceIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "20px", px: 2 }}>
                <Typography variant="p" component="span">
                  FSCA Licence Registration
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                variant="body2"
                color="text.secondary"
              >
                15th December 2021
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="secondary">
                  <ThumbUpOffAltIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "20px", px: 2 }}>
                <Typography variant="p" component="span">
                  Due Diligence Approval by Constantia
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "3rem 0" }}
                variant="body2"
                color="text.secondary"
              >
                04 January 2022
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="secondary" variant="outlined">
                  <BookIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "20px", px: 2 }}>
                <Typography variant="p" component="span">
                  Binder Agreement with Constantia
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                variant="body2"
                color="text.secondary"
              >
                28 January 2022
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="secondary">
                  <BookmarksIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "20px", px: 2 }}>
                <Typography variant="p" component="span">
                  Binder Notification to FSCA
                </Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "3rem 0" }}
                variant="body2"
                color="text.secondary"
              >
                01 March 2022
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="secondary" variant="outlined">
                  <AssuredWorkloadIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "20px", px: 2 }}>
                <Typography variant="p" component="span">
                  First Day of Operations
                </Typography>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </div>
        <div className="col-xs-12 col-md-8 col-sm-8">
          <div className=" mt-2 card abouthistory shadow">
            <div className="p-3">
              <Typography>
                <b>Arella </b> Underwriting Management Agency is a 100% black
                owned UMA authorised to underwrite and administer funeral
                products, which means partnering with us allows you to align to
                the transformation goals of the financial sector.
              </Typography>
            </div>
          </div>
          <div className=" mt-3 card shadow">
            <div className="p-3">
              <Typography>
                <b>Arella</b> UMA was founded by Sitsaba Bekwa who has many
                years of experience in the non-life insurance broking market.
              </Typography>
            </div>
          </div>

          <div className=" mt-3 card shadow">
            <div className="p-3">
              <Typography>
                <b>Our CEO</b>, Dan Moeti, has over 25 years insurance
                experience both in non-life and life insurances with extensive
                executive management experience in this sector, as well as the
                aviation and public sectors.
              </Typography>
            </div>
          </div>

          <div className=" mt-3 card shadow">
            <div className="p-3">
              <Typography>
                <b> Arella UMA</b> obtained its FSP licence in December 2020
                (FSP no 51097) and launched on the 1st March 2022.
              </Typography>
            </div>
          </div>

          <div className=" mt-3 card shadow">
            <div className="p-3">
              <Typography>
                <b>The creation</b> The creation of Arella UMA has been a 3-year
                journey involving extensive research into South Africa and the
                rest of Africa’s buying trends within the funeral market. This
                has led us to develop specific products and go-to-market
                strategies for optimal success.
              </Typography>
            </div>
          </div>

          <div className=" mt-3 card shadow">
            <div className="p-3">
              <Typography>
                <b>We have</b> the capability to provide white-labelled and
                tailor-made group funeral administration and benefits solutions.
              </Typography>
            </div>
          </div>

          <div className=" mt-3 card shadow">
            <div className="p-3">
              <Typography>
                <b>Arella is</b> supported technically and financially by
                Constantia Insurance Group and has signed a binder agreement to
                perform all five legislated binder functions with all
                legislative requirements complied with.
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 p-5 ">
          <Typography
            className="p-2 text-center"
            style={{
              backgroundColor: "purple",
              color: "white",
              fontSize: "24px",
            }}
          >
            Meet The Founders
          </Typography>
          <div
            className=" shadow p-5"
            style={{
              background:
                "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(250,249,250,1) 100%, rgba(234,251,255,1) 100%)",
            }}
          >
            <div className="row" style={{ height: "100%" }}>
              <div
                className="col-xs-12 col-sm-6 col-md-6"
                style={{ height: "100%" }}
              >
                <h4>Dan Moeti</h4>
                <h6>
                  BA (UDW), BB&A Hon(Stellenbosch), MBA (Stellenbosch), FIISA
                </h6>
                <div className="card shadow">
                  <div className="founder row">
                    <div
                      className="col-lg-6 col-md-6col-sm-6"
                      style={{ height: "100%" }}
                    >
                      {" "}
                      <img
                        src={DanImage}
                        alt=""
                        style={{
                          width: "100%",
                          height: "auto",
                          objectFit: "fill",
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 p-3">
                      <p style={{ height: "100%" }}>
                        Arella is spearheaded by <strong>Dan Moeti </strong>
                        as the CEO. He has had an illustrious career in
                        insurance and risk management spanning over two decades.
                        He has been an executive and board member in both
                        private and public companies, and a key decision maker
                        at the following companies, among others:
                      </p>
                    </div>
                    <div
                      className="slider col-xs-12 col-sm-12 col-md-12"
                      style={{ height: "100%" }}
                    >
                      <Slider {...Settings}>
                        <div
                          style={{ position: "relative", overflow: "hidden" }}
                        >
                          <img
                            src={pick_n_pay_logo}
                            style={{ width: "100%", height: "100px" }}
                          />
                        </div>
                        <div
                          style={{ position: "relative", overflow: "hidden" }}
                        >
                          <img
                            src={AlexanderForbes_logo}
                            style={{ width: "100%", height: "100px" }}
                          />
                        </div>
                        <div>
                          <img
                            src={Absa_logo}
                            style={{ width: "100%", height: "100px" }}
                          />
                        </div>
                        <div>
                          <img
                            src={psg_logo}
                            style={{ width: "100%", height: "100px" }}
                          />
                        </div>
                        <div>
                          <img
                            src={Hollard_logo}
                            style={{ width: "100%", height: "100px" }}
                          />
                        </div>
                        <div>
                          <img
                            src={south_african_airways_airline_logo}
                            style={{ width: "100%", height: "100px" }}
                          />
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="col-xs-12 col-sm-6 col-md-6"
                style={{ height: "100%" }}
              >
                <h4 style={{ paddingTop: "1rem;" }}>Sitsaba Bekwa</h4>
                <h6>BA (HMS) (UWC), Dip in Business Management</h6>
                <div className="card shadow" style={{ height: "auto" }}>
                  <div className="founder row">
                    <div
                      className="col-lg-6 col-md-6col-sm-6"
                      style={{
                        height: "26rem",
                        overflow: "hidden",
                        padding: "1rem",
                      }}
                    >
                      {" "}
                      <img
                        src={SetsabaImage}
                        alt="Setsaba Bekwa"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6col-sm-6 p-3">
                      {" "}
                      <p style={{ height: "100%" }}>
                        <strong>Sitsaba</strong> is the Executive Chairman of
                        Arella and a formidable salesman having headed up the
                        Marsh Africa sales team for 7 years. He was responsible
                        for the sales strategy and marketing strategy in 7
                        African countries and for managing 14 sales staff. As an
                        Executive Chairman, Sitsaba is responsible for the
                        strategic direction of Arella UMA, including leveraging
                        the high-level relationships he has built over the years
                        in the industry.
                      </p>
                    </div>
                    <div className="slider col-xs-12 col-sm-12 col-md-12">
                      <Slider {...Settings2}>
                        <div
                          style={{ position: "relative", overflow: "hidden" }}
                        >
                          <img
                            src={AlexanderForbes_logo}
                            style={{ width: "100%", height: "100px" }}
                          />
                        </div>
                        <div
                          style={{ position: "relative", overflow: "hidden" }}
                        >
                          <img
                            src={marsh_log}
                            style={{ width: "100%", height: "100px" }}
                          />
                        </div>
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
