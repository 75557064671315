import { Button, FormControl, IconButton, Input, InputAdornment, InputLabel, MenuItem, Select } from '@material-ui/core'
import { AccountCircleOutlined, Lock, Visibility, VisibilityOff } from '@material-ui/icons'
import React, { useState, useEffect,useRef } from 'react'

import Axios from 'axios';
import NavBar from "../../NavBar/NavBar";
import { useNavigate } from "react-router-dom";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import lottie from 'lottie-web';

import helloCircle from '../../../Assets/helloCircle.json'


export default function PartnerSignin() {

    const [showPassword, setshowPassword] = useState(false);
    const [partnerName, setpartnerName] = useState('');
    const [partnererror, setpartnererror] = useState(false)

    const [userLoginData, setUserLoginData] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userEmailError, setuserEmailError] = useState(false)
    const [userPassword, setUserPassword] = useState('');
     const [passworderror, setpassworderror] = useState(false)


    let navigate = useNavigate();

    const container = useRef(null);
    

    useEffect( ()=>{

      lottie.loadAnimation({
          container: container.current,
          renderer:'svg',
          loop:true,
          autoplay:true,
          animationData:helloCircle
      })
      
      },[])

  const handleChange = (event) => {
    setpartnerName(event.target.value);
  };
    const handleClickShowPassword = () => {
    setshowPassword(!showPassword);
  };
    const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function displayIncorrectPassword(){

      return(
            <Dialog open={true}>
                <DialogTitle>Incorrect Username\Password provided</DialogTitle>
                <p>kishav</p>
            </Dialog>
      );
  }

  function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;
  
    const handleClose = () => {
      onClose(selectedValue);
    };
  
    const handleListItemClick = (value) => {
      onClose(value);
    };
  
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Incorret Password\Username match</DialogTitle>
            <p className='mx-auto'>Please try again, thank you</p>
            <Button variant="contained" color='primary' className='mx-auto mb-3' onClick={()=>{
                setOpen(false)
            }}>Okay</Button>
            
      </Dialog>
    );
  }
  
  SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
  };

  const setLoginCookieValues = function(){

    document.cookie = `usernameCookie= ${partnerName}; `;
  
  }

  function getUserLoginData(){
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    Axios.post("https://arellanodesrv.link/checkUserLoginCredentials",{
            USEREMAIL:userEmail,
            PARTNERNAME: partnerName
        }).then((response)=>{
            setUserLoginData(response.data[0]);
            if(userLoginData.Password==userPassword){
                setLoginCookieValues();
                navigate("/Administration");
            }
            else{
                setOpen(true);
            }
        })
}

const [open, setOpen] = React.useState(false);

const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
   
  };
  useEffect(()=>{
        checkvalidation();

    },[partnerName,userEmail,userPassword])

    const checkvalidation=()=> {
      if(userPassword===''){
          setpassworderror(true)
      }
      else {
          setpassworderror(false)
      }
      if(userEmail===''){
          setuserEmailError(true)
      }
      else {
           setuserEmailError(false)
      }
      if(partnerName===''){
          setpartnererror(true)
      }
      else {
          setpartnererror(false)
      }
 }
  return (
        <div style={{height:'100vh'}}>

            <div>
                <NavBar />
            </div>

            <div className='p-5 ' style={{background:'#f5f5f5',height:'100%'}}>

            <div className='mt-5' style={{height:'fit-content'}}>
                <div className='' >
                <div className='row text-center' >
                        <div className='col-xs-12 col-sm-6 col-md-6 mx-auto'>

                                 <div className='lead p-2' style={{background:'purple'}}>
                                      <Typography style={{color:'white',fontSize:'18px'}}>Partner Login</Typography>
                                      <p style={{fontSize:'14px', color:'white'}}>(Please provide only accurate and no later than 3 months information)</p>
                                </div>
                            <div  className='card shadow  container '>

                                <div className='p-5'>
                                    <div className="">
                                    <div className="text-center">

                                    <div className="mx-auto" ref={container}  style={{ width:'5rem'}}></div>

                                            <FormControl   margin='normal' fullWidth={true}>
                                                    <InputLabel id="-select-label">Select Partner</InputLabel>
                                                    <Select
                                                        labelId="select-label"
                                                        id="demo-simple-select"
                                                        value={partnerName}
                                                        label="Select partner "
                                                        onChange={handleChange}
                                                        error={partnererror}
                                                       
                                                    >
                                                        <MenuItem value={'Bonitas'}>Constantia</MenuItem>
                                                        
                                                    </Select>
                                                    </FormControl>
                                            
                                        <div className="username mb-1">
                                            <FormControl sx={{ m: 2, width: '25ch' }} margin='normal' fullWidth={true} variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-username">Username</InputLabel>
                                                <Input id="outlined-adornment-username"
                                                type={'text'}
                                                value={userEmail}
                                                error={userEmailError}
                                                onChange={(e)=>setUserEmail(e.target.value)}
                                                startAdornment={<InputAdornment position="start">
                                                    <AccountCircleOutlined />
                                                    </InputAdornment>
                                                }
                                                label="Username"/>
                                            </FormControl>
                                        </div>
                                        <div className="password">
                                            <FormControl sx={{ m: 1, width: '25ch' }} fullWidth={true}  variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                                <Input id="outlined-adornment-password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={userPassword}
                                                error={passworderror}
                                                onChange={(e)=>setUserPassword(e.target.value)}
                                                startAdornment={<InputAdornment position="start">
                                                    <Lock />
                                                    </InputAdornment>
                                                }
                                                endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end" >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                } label="Password"/>
                                            </FormControl>
                                        </div>
                                        <div className="signinbutton mx-auto">
                                            <Button className='mx-auto mt-3' variant="contained" color='primary' onClick={getUserLoginData}>Sign in</Button>
                                            <SimpleDialog
                                                
                                                open={open}
                                                onClose={handleClose}
                                            />
                                        </div>
                                    </div>
                                </div>
                                </div>
      
                        </div>

                        </div>
                    </div>
                </div>
            </div>
  


      </div>
   

        </div>

    
  )
}
