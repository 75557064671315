import gsap from 'gsap';
import './SplashScreen.css'
import {useEffect, useRef,useState} from "react";
import checkCookie from '../../Cookies';

export default function SplashScreen(scope){
    let splashCookies= sessionStorage.getItem("splash_Cookies")
    const splash =useRef();
    const logo = useRef()
    const wording =useRef();
    const vect1 = useRef()
    const vect2 = useRef()
    const vect3 = useRef()
    const vect4 = useRef()
    const vect5 = useRef()
    const checkSplashCookie=()=>{
        if(!splashCookies){
            sessionStorage.setItem("splash_Cookies",false)
        }
    }
    useEffect(()=>{
        gsap.to(logo.current,{
            height: 300,
            delay:.1,
            duration:1,
        });
        gsap.to(wording.current,{
            opacity:1,
            x:20,
            duration:2,
            delay:.8,
        })
       gsap.to(splash.current,{
            y:-1000,
            delay:3,
            duration:1,
            display:'none',
        })
        gsap.from(vect1.current,{
            scale:.1,
            delay:.1,
        })
        gsap.from(vect2.current,{
            scale:.1,
            delay:.2,
        })
        gsap.from(vect3.current,{
            scale:.1,
            delay:.3
        })
        gsap.from(vect4.current,{
            scale:.1,
            delay:.4,
        })
        gsap.from(vect5.current,{
            scale:.1,
            delay:.45,
        })
         checkSplashCookie();

    },[])
    return (
      <div>
          {!splashCookies? <div ref={splash} className='splashScreen'>
          <div className='logo d-flex flex-column'>
              <svg ref={logo} viewBox="0 0 600 600" fill="none" className='w-100'>
                  <g  id="arellaLogo" >
                      <path ref={vect1} id="Vector1" d="M583.993 340.593C557.765 147.415 195.816 171.219 94.6064 11C76.0924 216.69 487.411 187.088 525.365 349.748C550.424 457.144 418.184 607.752 427.858 599.689C455.321 576.801 598.329 446.185 583.993 340.593Z" fill="#662F94"/>
                      <path ref={vect2} id="Vector2" d="M102.006 150.005C102.004 150.003 102.002 150.002 102 150C102.002 150.002 102.004 150.003 102.006 150.005C188.228 221.459 205.536 209.784 295.16 236.424C399.004 267.29 482.758 313.686 482.758 332.925C482.758 333.398 482.852 333.711 482.923 333.871C483.049 334.041 483 334.045 482.923 333.871C481.736 332.267 464.929 315.801 307.832 293.225C118.772 266.056 107.481 154.579 102.006 150.005Z" fill="#662F94"/>
                      <path ref={vect3} id="Vector3" d="M316.242 314.565C245.174 314.565 190.483 291.841 167 282C173.798 292.043 204.697 359 330.455 359C364.452 359 373.713 345.913 497 359C473.62 346.928 417.899 314.565 316.242 314.565Z" fill="#662F94"/>
                      <path ref={vect4} id="Vector4" d="M353.624 379.362C303.734 387.562 285.77 377.547 256 379.362C282.793 389.341 321.904 416.903 373.334 408.392C424.764 399.88 394.275 392.062 496.827 379.362C501.104 378.832 425.379 367.567 353.624 379.362Z" fill="#662F94"/>
                      <path ref={vect5} id="Vector5" d="M492.168 467.902C502.356 445.046 508.838 415.892 507.912 410C436.91 475.826 257.978 550.841 271.753 548.966C334.42 540.433 481.981 490.758 492.168 467.902Z" fill="black" stroke="black"/>
                  </g>
              </svg>
             
              <div className='word' ref={wording} style={{fontFamily:'Roboto',fontSize:'40px',opacity:0,textAlign:'center'}}>Arella</div>
          </div>
        </div>:''}
      </div>
    )
}