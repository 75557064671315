import gsap from 'gsap';
import {useState, useRef, useEffect} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText,} from "@material-ui/core";
import cookiebite from './Assets/cookie-bite.svg'

export default function Cookies (props){
    const newcookies = useRef();
    const [cookies,setcookies] = useState(checkCookie("cookies"));
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('paper');
    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };
    const handleClose = () => {
        document.cookie=`cookies=${true};`
        setcookies(true);
        setOpen(false);
    };
    const handleCloseCancel = () => {
        setOpen(false);
    };
    //const descriptionElementRef = useRef(null);
    useEffect(()=>{
        gsap.from(newcookies.current,{
            opacity:0,
            duration:1,
        });
        gsap.to(newcookies.current,{
            opacity:1,
            duration:1,
        });
        return ()=> {
            gsap.from(newcookies.current,{
            opacity:1,
            duration:1,
        });
        gsap.to(newcookies.current,{
            opacity:0,
            duration:1,
        });
        }

    },[])
  
    return (
        <div>
        {!cookies?<div className='Cookies w-100 text-center d-flex flex-column justify-content-around align-items-center p-5' ref={newcookies} style={{position:'fixed',left:'0',bottom:'0',backgroundColor:'purple',color:'white',height:'200px',zIndex:'100'}}>
                <div className='fw-bold'>
                    <img src={cookiebite} style={{width:'20px'}}  alt='setting arella cookies'/>
                    Cookies</div>
                <div className='text-center'>This site uses cookies to offer you a better browsing experience.<br/><a href='https://www.internetcookies.com/'> Find out more on how we use cookies and how you can change your settings</a> </div>
                <div className='w-50'>
                    <div className='d-flex justify-content-around mt-5'>
                        <Button variant='outlined' className='mx-5' onClick={()=>handleClose()} style={{border:'solid silver 1px',color:'white'}}>AGREE</Button>
                        <Button variant='outlined'  className='mx-5' onClick={handleClickOpen('paper')} style={{border:'solid silver 1px',color:'white'}}>READ OUR COOKIE POLICY</Button>
                    </div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        scroll={scroll}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                    >

                        <DialogContent dividers={scroll === 'paper'}>
                            <DialogContentText id="scroll-dialog-description"  tabIndex={-1}>
                                <div> <h4>Generic Cookie Policy Template</h4><br />
                                    Please read this cookie policy carefully before using Arella website
                                    (“website”, "service") operated by Arella underwriting Agency. <br />
                                    What are cookies?
                                    Cookies are simple text files that are stored on your computer or mobile device by a website’s
                                    server. Each cookie is unique to your web browser. It will contain some anonymous information
                                    such as a unique identifier, website’s domain name, and some digits and numbers.
                                    What types of cookies do we use?<br/><br/>
                                    <h5>Necessary cookies</h5>
                                    Necessary cookies allow us to offer you the best possible experience when accessing and
                                    navigating through our website and using its features. For example, these cookies let us
                                    recognize that you have created an account and have logged into that account.<br /><br/>
                                    <h5>Functionality cookies</h5>
                                    Functionality cookies let us operate the site in accordance with the choices you make. For
                                    example, we will recognize your username and remember how you customized the site during
                                    future visits.<br/><br/>
                                    <h4>Analytical cookies</h4>
                                    These cookies enable us and third-party services to collect aggregated data for statistical
                                    purposes on how our visitors use the website. These cookies do not contain personal
                                    information such as names and email addresses and are used to help us improve your user
                                    experience of the website.<br/><br/>
                                    <h4>How to delete cookies?</h4>
                                    If you want to restrict or block the cookies that are set by our website, you can do so through
                                    your browser setting. Alternatively, you can visit <a href='www.internetcookies.org'>www.internetcookies.org</a>, which contains
                                    comprehensive information on how to do this on a wide variety of browsers and devices. You
                                    will find general information about cookies and details on how to delete cookies from your
                                    device.
                                    Contacting us
                                    If you have any questions about this policy or our use of cookies, please contact us.</div>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseCancel}>Cancel</Button>

                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        :""}
        </div>
            )
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}
function checkCookie(cookie) {
    let mycookies = getCookie(cookie);
    return true;
}