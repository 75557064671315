import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-notifications-component/dist/theme.css'
import { Routes, Route, Link } from "react-router-dom";
import HomePage from "./Components/HomePage/Homepage";
import BrokerSignin from './Components/Portals/Broker/BrokerSignin';
import PartnerSignin from './Components/Portals/Partner/PartnerSignin';
import PartnerForgotPassword from './Components/Portals/Partner/PartnerForgotPassword';
import BrokerForgotPassword from './Components/Portals/Broker/BrokerForgotPassword';
import Qoute from './Components/Qoute/Qoute';
import PartnerPortal from './PartnerPortal';
import { ReactNotifications } from 'react-notifications-component'

import Sales from './Sales'
import Administration from './Administration'
import SignUpPolicy from './SignUpPolicy';
import SalesDashboard from './SalesDashboard'
import Register from './Register';
import AboutUs from './aboutUs';
import ExecutiveTeam from './ExecutiveTeam';
import Partners from './Partners';
import Distribution from './Distribution';
import Capabilities from './Capabilities';
import ParticleBackground from './ParticleBackground'
import ContactUS from './Components/HomePage/ContactUS';

function App() {
  return (
    <div className="App">
       <ReactNotifications />
        <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/brokersignin" element={<BrokerSignin/>} />
            <Route path="/partnersignin" element={<PartnerSignin/>} />
            <Route path="/getaqoute" element={<Qoute/>} />
            
            <Route path="/brokerfpassword" element={<BrokerForgotPassword/>} />
            <Route path="/PartnerPortal" element={<PartnerPortal/>} />
            <Route path="/Sales" element={<Sales/>} />
            <Route path="/contactUs" element={<ContactUS/>} />
            <Route path="/Administration" element={<Administration/>} />
            <Route path="/SignUpPolicy" element={<SignUpPolicy/>} />
            <Route path="/SalesDashboard" element={<SalesDashboard/>} />
            <Route path="/Register" element={<Register/>} />
            <Route path="/AboutUs" element={<AboutUs/>} />
            <Route path="/ExecutiveTeam" element={<ExecutiveTeam/>} />
            <Route path="/Distribution" element={<Distribution/>} />
            <Route path="/Capabilities" element={<Capabilities/>} />
            <Route path="/Partners" element={<Partners/>} />
            <Route path="/ParticleBackground" element={<ParticleBackground/>} />
            

        </Routes>
    </div>
  );
}

export default App;
