
import './Footer.css'
import QRCode from '../../../../Assets/QRCode.png'
import { Link, useNavigate } from 'react-router-dom';
export default function Footer(){
    const navigate= useNavigate();
    const date = new Date();
    return (
        <section  className='footer'>
            <div className="container">
                <div className="row text-start text-xs-center text-sm-left text-md-left">
                    <div className="col-xs-12 col-sm-4 col-md-4 d-flex flex-column">
                        <h5>COMPANY</h5>
                        <div className='links'>
                            <Link to='/'><h4>Home </h4></Link>
                            <Link to='/AboutUs'><h4>Company Overview</h4></Link>
                            <Link to='/AboutUs'><h4>About Us</h4></Link>
                           <Link to='/Partners'> <h4>Patners</h4></Link>
                           <Link to='/Distribution'> <h4>distribution</h4></Link>
                           <Link to='/Capabilities'> <h4>Capabilities</h4></Link>
                           <Link to='/Executive Team'> <h4>Executive Team</h4></Link>
                            <h4>FAQs</h4>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4">
                        <h5>OUR GOALS</h5>
                        <p style={{color:'azure'}}>
                            Combat stress about funeral finance for<br/>
                            people within the country as a start. We<br/>
                            aim to give funeral cover to those whom require<br/>
                            it, simultaneously allowing average citizens<br/>
                            to register and have a source of comfort. We <br/>
                            choose to brighten SA’s prosperity. </p>
                           
                    </div>
                    <div id='footer' className="col-xs-12 col-sm-4 col-md-4">
                        <h5>CONTACT US</h5>
                        <Link to='/ContactUs#top' style={{color:'white',textDecoration:'none'}}>
                            <h4 >Send Enquiry</h4>
                        </Link>
                        <h4>Send a complaint</h4>
                        <h4>Report Fraud</h4>
                        <hr/>
                        <h5 className='p-0'>Address</h5>
                        <ul className="quick-links  text-white">
                            <li>Webber Wentzel Building  TBE,<br/> North Wing, 2nd Floor <br/>90 Rivonia Road <br/> SANDTON, Johannesburg<br/>
</li>
                            <li><br/>Support@arellainsurance.com</li>
                            <li> 078 085 4208</li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <hr/>
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white d-flex justify-content-sm-around flex-column">
                        <p>Underwriting Management Agency (Pty) Ltd is an authorised Financial Services Provider (FSP 51097).</p>
                        <p className="p">© {date.getFullYear()} All right Reversed. <strong>DISCLAIMER</strong></p>
                    </div>
                    <hr/>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 ">
                        <ul className="list-unstyled list-inline social text-center d-flex justify-content-evenly flex-row">
                            <li className="list-inline-item">
                                <a href="#"><i className="fa fa-facebook"></i></a>
                            </li>
                            <li className="list-inline-item"><a href="#"><i className="fa fa-twitter"></i></a>
                            </li>
                            <li className="list-inline-item"><a href="#"><i
                                className="fa fa-linkedin"></i></a></li>
                            <li className="list-inline-item"><a href="#" target="_blank"><i
                                className="fa fa-envelope"></i></a></li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 text-center " style={{fontSize:'12px',color:'silver'}}>  Developed by  <a style={{textDecoration:'none',color:'silver',fontWeight:'bold',paddingLeft:'.2rem',fontSize:'16px'}} href="https://ndalaict.com">Ndala ICT Solutions</a></div>
                </div>
            </div>
        </section>
        )
}