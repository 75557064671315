import './Homepage.scss'
import NavBar from "../NavBar/NavBar";
import Footer from './Sections/Footer/Footer'
import SplashScreen from '../SplashScreen/splashScreen';
import LandingPage from './Sections/LandingPage/LandingPage'
import lottie from 'lottie-web';
import { useState, useEffect, useRef } from 'react'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Cookies from '../../Cookies'
import gsap from 'gsap';
import teamworkLOTTIE from '../../Assets/teamwork.json'
import visionLOTTIE from '../../Assets/vision.json'
import valuesLOTTIE from '../../Assets/valuez.json'
import { CardHeader } from '@material-ui/core';
import puzzlepiece from '../../Assets/puzzlepiece.svg'
import puzzlepeices from '../../Assets/puzzlepeices.svg'
import plus from '../../Assets/plus.svg'
import twopuzzlepieces from '../../Assets/twopuzzlepieces.png'
import funeral_Image from '../../Assets/funeral_Image.png'
import card_image from '../../Assets/card_image.svg'
import health_accident from '../../Assets/health_and_accident.svg'
import cart_warranty from '../../Assets/cart_warranty.png'
export default function HomePage(){
    const container = useRef(null);
    const container1 = useRef(null);
    const container2 = useRef(null);
    useEffect( ()=>{
        gsap.to(".history",{
            scrollTrigger:'.history',
            x:500
        })
        lottie.loadAnimation({
            container: container.current,
            renderer:'svg',
            loop:true,
            autoplay:true,
            animationData:teamworkLOTTIE
        })
         lottie.loadAnimation({
                container: container1.current,
                renderer:'svg',
                loop:true,
                autoplay:true,
                animationData:visionLOTTIE
            })
        },[])
            useEffect( ()=>{

                lottie.loadAnimation({
                    container: container2.current,
                    renderer:'svg',
                    loop:true,
                    autoplay:true,
                    animationData:valuesLOTTIE
                })
                },[])
    return (
        <div className='homepage'>
            <Cookies/>
            <SplashScreen/> 
            <NavBar />
            <div className='pt-5'>
            <LandingPage/>
                <div className='row'>
                    <div className='whatDefinesUs text-center col-sm-12 col-md-12 col-xs-12 p-5' >
                       <Typography className='p-2 heading' style={{backgroundColor:'purple' ,color:'white', fontSize:'1.5vw'}}>What Defines Us</Typography>
                            <div className='card shadow p-3'>
                                <div className='row'>
                                    <div className='col-xs-12 my-2 col-sm-4 col-md-4'>
                                            <div className='card shadow p-2 h-100'>
                                            <Typography className='mx-auto card_heading ' style={{fontSize:'1.4vw',height:'100%'}} >Mission</Typography>
                                                <div className='mx-auto card_gif' style={{ width:'20vw',height:'fit-content'}} ref={container}>
                                                </div>
                                                <Typography className='p-3 card_body_text'><b>Our mission</b> is to make
                                                            financial services
                                                            accessible to all
                                                            segments of the South
                                                            African population,
                                                            inclusive of those that are
                                                            under-served.
                                                </Typography>
                                            </div>
                                    </div>
                                    <div className='col-xs-12 my-2 col-sm-4 col-md-4'>
                                            <div className='card  shadow p-2 h-100'>
                                            <Typography className='mx-auto card_heading' style={{fontSize:'1.4vw'}} >Vision</Typography>
                                                <div className='mx-auto card_gif' style={{width:'20vw'}} ref={container1}>

                                                </div>
                                                <Typography className='p-3 card_body_text'><b>To be </b>
                                                 the
                                                underwriting manager
                                                of choice to brokers
                                                and non-insurance
                                                partners.
                                                </Typography>
                                             </div>
                                    </div>
                                    <div className='col-xs-12 my-2 col-sm-4 col-md-4'>
                                           <div className='card h-100 shadow p-2'>
                                           <Typography className='mx-auto mb-5 card_heading' style={{fontSize:'2rem'}}>Values</Typography>
                                                <div className='mx-auto card_gif' style={{width:'20vw'}} ref={container2}>

                                                </div>
                                                <Typography className='p-3 card_body_text'>
                                                <b>Our employees</b> come first. We believe in collaboration. 
                                                Integrity defines us. Technology driven. Serving the community
                                                </Typography>
                                          </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-xs-12 px-5 my-5'>
                       <Typography className='p-2 text-center' style={{backgroundColor:'purple' ,color:'white', fontSize:'20px',fontFamilty:"'Barlow Condensed', sans-serif;"}}>Our Product Development Philosophy</Typography>
                     </div>
                </div>
                <div className='row px-4 mb-5'>
                <div className='history col-sm-12 col-md-12 col-xs-12 ' >
                        <div className='  card shadow my-4 '>
                            <div className='p-4'>
                                <Typography  >
                                <b>Arella UMA</b> offers client specific products that not only suit the needs of our clients but their
                                members financial needs as well.
                                </Typography>
                            </div>
                      </div>
                      <div className=' card shadow my-4'>
                            <div className='p-3'>
                                <Typography  >
                                <b>Through </b>our partnership with Constantia (insurance partner) we offer very aggressive
                                pricing that gives great benefits to the policyholders without breaking their pockets. 
                                </Typography>
                            </div>
                      </div>
                      <div className=' my-4 card shadow'>
                            <div className='p-3'>
                                <Typography  >
                                <b>Arella UMA</b> currently offers diverse funeral covers geared towards groups and individuals.
                                These covers are underwritten differently to give the necessary benefits to each group of
                                clients. 
                                </Typography>
                            </div>
                      </div>
                      <div className='my-4  card shadow'>
                            <div className='p-3'>
                                <Typography  >
                                <b>We have a</b> wealth of insights into the South African insurance landscape which drive our
                                innovative and target-driven solutions that cater to the cultural nuances of our diverse
                                country.
                                </Typography>
                            </div>
                      </div>
                      <div className=' my-4 card shadow'>
                            <div className='p-3'>
                                <Typography  >
                                    <b>We structure</b> our products with end consumer in mind whilst ensuring that our pricing and
                                    benefits are relevant.
                                </Typography>
                            </div>
                      </div>
                      <div className=' my-4 card shadow'>
                            <div className='p-3'>
                                <Typography  >
                                <b>We design</b> our products and tailor them to improve the partner’s own brand affinity with
                                their customers or members
                                </Typography>
                            </div>
                      </div>
                </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-sm-12 col-md-12 col-xs-12 px-5 row'>
                       <Typography className='p-2 text-center' style={{backgroundColor:'purple' ,color:'white', fontSize:'24px'}}>Our Product Offering</Typography>
                      
                    <div className="productoffers row">
                            <div className="product col-lg-3 col-md-6 col-sm-6">
                                <div className="product_card" style={{backgroundColor:'#85559e'}}>
                                    <div className="heading">
                                        <img src={funeral_Image} alt="" className="" />
                                        <h5>Funeral Cover </h5>
                                    </div>
                                    <div className="list">
                                        <ul>
                                            <div className="li">Individual</div>
                                            <div className="li">Family</div>
                                            <div className="li">Parent</div>
                                            <div className="li">Extended Family</div>
                                            <div className="li">Memorial Benefit </div>
                                            <div className="li">Monthly Provider</div>
                                        </ul>
                                    </div>
                                </div>
                                 <div className="gap" style={{backgroundColor:'#85559e'}}>
                                
                                </div>
                            </div>
                            <div className="product col-lg-3 col-md-6 col-sm-6 ">
                                <div className="product_card">
                                    <div className="heading">
                                        <img src={card_image} alt="" className=""/>
                                        <h5>Credit Life </h5>
                                    </div>
                                    <div className="list">
                                        <ul>
                                            <div className="li">Credit Life on Unsecured Debt</div>
                                            <div className="li">Consolidated</div>
                                            <div className="li">Credit Life on Mortgages</div>
                                        </ul>
                                    </div>
                                </div>
                                 <div className="gap">
                                   
                                </div>
                            </div>
                            <div className="product col-lg-3 col-md-6 col-sm-6">
                                <div className="product_card" style={{backgroundColor:'#85559e'}}>
                                    <div className="heading">
                                        <img src={health_accident} alt="" className="" />
                                        <h5>Health & Accident  </h5>
                                    </div>
                                    <div className="list">
                                        <ul>
                                            <div className="li"><span>Commuter Cover</span> </div>
                                            <div className="li"><span>Group Personal Accident</span></div>
                                            <div className="li"><span>Individual Personal Accident</span> </div>
                                           
                                        </ul>
                                    </div>
                                </div>
                                 <div className="gap" style={{backgroundColor:'#85559e'}}>
                                </div>
                            </div>
                            <div className="product col-lg-3 col-md-6 col-sm-6">
                                <div className="product_card">
                                    <div className="heading">
                                        <img src={cart_warranty} alt="" className="" />
                                        <h5>Warranty</h5>
                                    </div>
                                    <div className="list">
                                        <ul>
                                            <div className="li"><span>Home</span></div>
                                            <div className="li"><span>Devices</span></div>
                                            <div className="li"><span>Eyewear</span></div>
                                            <h5>Other</h5>
                                            <div className="li"><span>Extended Family</span></div>
                                            <div className="li"><span>Memorial Benefit </span></div>
                                            <div className="li"><span>Monthly Provider</span></div>
                                        </ul>
                                    </div>
                                </div>
                                <div className="gap">
                                   
                                </div>
                            </div>
                            
                           
                    </div>
                    </div>
                </div>

            </div>


            <Footer/>
        </div>
    )
}
