import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import NavBar from "./Components/NavBar/NavBar";
import DanMember from "./Assets/DanMember.JPG";
import SitMember from "./Assets/SitsabaMember.JPG";
import TebohoMember from "./Assets/TebohoMember.JPG";
import GordinMember from "./Assets/GordinMember.JPG";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import constansia from "./Assets/constansia.JPG";
import khl from "./Assets/khl.JPG";
import smith_logo from "./Assets/smith_logo.jpg";
import leap from "./Assets/leap.JPG";
import broker from "./Assets/broker.JPG";

import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import Footer from "./Components/HomePage/Sections/Footer/Footer";

export default function Partners() {
  return (
    <div>
      <NavBar />

      <div
        className="row pt-5"
        style={{
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(96,9,121,1) 56%, rgba(0,212,255,1) 100%)",
        }}
      >
        <div className="col-xs-12 col-sm-12 col-md-12 p-4">
          <div className="p-5">
            <div className="card shadow p-5 text-center">
              <Typography
                style={{
                  fontSize: "1.5rem",
                  color: "purple",
                  overflowWrap: "normal",
                }}
              >
                Partnering Solutions{" "}
                <ConnectWithoutContactIcon style={{ fontSize: "45px" }} />
              </Typography>
            </div>
          </div>
        </div>
      </div>

      <div className="row m-2">
        <div className="col-xs-12 col-sm-12 col-md-12 my-3 mx-auto">
          <Typography
            className="p-3 text-center"
            style={{
              backgroundColor: "purple",
              color: "white",
              fontSize: "21px",
            }}
          >
            Our Three Business Models for Partnerships
          </Typography>
          <div className="shadow p-3">
            <div className="row p-3">
              <div
                className="col-xs-12 col-sm-3 col-md-3 p-5 m-2"
                style={{ backgroundColor: "purple" }}
              >
                <Typography
                  className="text-center mt-3"
                  style={{ color: "white", fontSize: "20px" }}
                >
                  White-Labelled
                </Typography>
              </div>
              <div
                className="col-xs-12 col-sm-8 col-md-8 p-5 m-2"
                style={{ border: "1px solid silver" }}
              >
                <Typography>
                  These are entities that have the desire to establish a legal
                  structure which will provide the partner’s customers with
                  insurance. There is co-branding here.
                  <b>
                    {" "}
                    Both parties participate in the insurance value chain
                    functions.
                  </b>{" "}
                  FSP licence may be required.
                </Typography>
              </div>
            </div>

            <div className="row p-3 ">
              <div
                className="col-xs-12 col-sm-3 col-md-3 p-5 m-2"
                style={{ backgroundColor: "#bf71e3" }}
              >
                <Typography
                  className="text-center mt-3"
                  style={{ color: "white", fontSize: "20px" }}
                >
                  Intermediaries
                </Typography>
              </div>
              <div
                className="col-xs-12 col-sm-8 col-md-8 p-5 m-2"
                style={{ border: "1px solid silver" }}
              >
                <Typography>
                  These are intermediaries such as brokers, agents etc. who{" "}
                  <b>perform the selling function</b> and receive commission in
                  line with regulation. FSP licence required, products can be
                  white-labelled or Arella branded.
                </Typography>
              </div>
            </div>

            <div className="row p-3">
              <div
                className="col-xs-12 col-sm-3 col-md-3 p-5 m-2"
                style={{ backgroundColor: "purple" }}
              >
                <Typography
                  className="text-center mt-3"
                  style={{ color: "white", fontSize: "20px" }}
                >
                  Arella Branded
                </Typography>
              </div>
              <div
                className="col-xs-12 col-sm-8 col-md-8 p-5 m-2"
                style={{ border: "1px solid silver" }}
              >
                <Typography>
                  These are entities that do not want to participate in the
                  value chain functions/ insurance functions. They therefore
                  only <b>provide access to their customer base</b>, through
                  either leads or space rental on their premises.
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 p-5">
          <Typography
            className="p-3"
            style={{
              backgroundColor: "purple",
              color: "white",
              fontSize: "21px",
            }}
          >
            Arella’s Strategic and Technical Partners
          </Typography>

          <div className="row mt-2">
            <div className="col-xs-12 col-sm-4 col-md-4">
              <div className="card  shadow p-3 m-2" style={{ height: "100%" }}>
                <div className="mx-auto">
                  <img
                    className="img-fluid mx-auto"
                    src={constansia}
                    alt=""
                    width="220"
                    height="120"
                  />
                </div>
                <div className="mt-5 p-3">
                  <Typography>
                    Arella has signed a <b>Binder Agreement</b> with Constantia
                    to perform all five legislated binder functions. Constantia
                    provides technical and regulatory support as well as
                    oversight to Arella. Arella performs outsource functions
                    where applicable regarding
                    <b> quality assurance</b> and <b>broker screening</b> for
                    the agency agreements.
                  </Typography>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4">
              <div className="card shadow p-3 m-2" style={{ height: "100%" }}>
                <div className="mx-auto">
                  <img
                    className="img-fluid mx-auto"
                    src={khl}
                    alt=""
                    width="115"
                    height="120"
                  />
                </div>
                <div className="mt-4 mb-5 p-3">
                  <Typography>
                    <b> Meera Vala</b> of Khilana Compliance Consulting is
                    registered as <b>Compliance Officer</b> of Arella on the FSP
                    Licence 51097 and performs all the required compliance
                    functions as required by FAIS Act and other applicable
                    legislation.
                  </Typography>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4">
              <div className="card shadow p-3 my-2" style={{ height: "100%" }}>
                <div className="mx-auto">
                  <img className="img-fluid mx-auto" src={smith_logo} alt="" />
                </div>
                <div className="mt-4 mb-5  p-3">
                  <Typography className="mb-5">
                    <b>Smith Bekwa Incorporated Attorneys</b> performs all the{" "}
                    <b>legal functions</b> for and on behalf of Arella under a
                    retainer agreement.
                  </Typography>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4">
              <div className="m-2 card shadow p-4 my-3">
                <div className="mx-auto">
                  <img
                    className="img-fluid mx-auto"
                    src={leap}
                    alt=""
                    width="210"
                    height="120"
                  />
                </div>
                <div className="Arella is a start-up underwriting management agency (UMA) currently authorised to provide funeral insurance products across numerous consumer and business segments.">
                  <Typography>
                    <b>Abongile Jojo </b>founder and director of{" "}
                    <b>Leap Actuaries & Consultants</b> who is also a
                    <b> Member of Actuarial Society of South Africa</b> performs
                    all the required actuarial functions in collaboration with
                    Constantia’s Actuarial Team.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 ol-sm-12 col-md-12 p-5">
          <Typography
            className="p-3"
            style={{
              backgroundColor: "purple",
              color: "white",
              fontSize: "21px",
            }}
          >
            What Our Brokers Are Saying
          </Typography>
        </div>
      </div>
      <div className="row shadow mx-5 mt-1 mb-4">
        <div className="col-xs-12 col-sm-4 col-md-4">
          <div className="p-2 d-flex justify-content-center">
            <img
              className="center"
              src={broker}
              alt="Malesa Ledwaba Profile Picture"
              style={{ height: "auto", width: "60%", borderRadius: "5px" }}
            />
          </div>
        </div>
        <div className="col-xs-12 col-sm-8 col-md-8">
          <div className=" p-3 mx-5">
            <Typography style={{ fontSize: "75px", color: "goldenrod" }}>
              “
            </Typography>
            <Typography
              className="mx-auto text-center"
              style={{ fontSize: "20px" }}
            >
              <p>
                Arella UMA provides very competitive pricing that gives good
                benefits to the policyholders with excellent speed to market.
              </p>
            </Typography>
            <div style={{ float: "right" }}>
              <Typography
                style={{ fontSize: "75px", color: "goldenrod", float: "right" }}
              >
                ”
              </Typography>
            </div>
            <Typography className="text-center">
              Malesela Ledwaba
              <br />
              Chairman, Morebo Brokerage & Financial Solutions
            </Typography>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
