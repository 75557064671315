import { Box, Button, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import React, { useState,ReactNode } from 'react'
import './Qoute.scss'
import Step1 from './Signups/Step1';
import Step2 from './Signups/Step2';
import Step3 from './Signups/Step3';
import Step4 from './Signups/Step4';
import Step5 from './Signups/Step5';
export default function Qoute() {
const steps = ['Tell us about you', 'Choose a product', 'Who do want to insure','Add Beneficiaries','Submit'];
const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  let count = 0

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <div className='qoute'>
        <div className='d-flex flex-column'>
            <svg  viewBox="0 0 600 600" fill="none" className='logo'>
                <g  id="arellaLogo" >
                    <path  id="Vector1" d="M583.993 340.593C557.765 147.415 195.816 171.219 94.6064 11C76.0924 216.69 487.411 187.088 525.365 349.748C550.424 457.144 418.184 607.752 427.858 599.689C455.321 576.801 598.329 446.185 583.993 340.593Z" fill="#662F94"/>
                    <path  id="Vector2" d="M102.006 150.005C102.004 150.003 102.002 150.002 102 150C102.002 150.002 102.004 150.003 102.006 150.005C188.228 221.459 205.536 209.784 295.16 236.424C399.004 267.29 482.758 313.686 482.758 332.925C482.758 333.398 482.852 333.711 482.923 333.871C483.049 334.041 483 334.045 482.923 333.871C481.736 332.267 464.929 315.801 307.832 293.225C118.772 266.056 107.481 154.579 102.006 150.005Z" fill="#662F94"/>
                    <path  id="Vector3" d="M316.242 314.565C245.174 314.565 190.483 291.841 167 282C173.798 292.043 204.697 359 330.455 359C364.452 359 373.713 345.913 497 359C473.62 346.928 417.899 314.565 316.242 314.565Z" fill="#662F94"/>
                    <path  id="Vector4" d="M353.624 379.362C303.734 387.562 285.77 377.547 256 379.362C282.793 389.341 321.904 416.903 373.334 408.392C424.764 399.88 394.275 392.062 496.827 379.362C501.104 378.832 425.379 367.567 353.624 379.362Z" fill="#662F94"/>
                    <path id="Vector5" d="M492.168 467.902C502.356 445.046 508.838 415.892 507.912 410C436.91 475.826 257.978 550.841 271.753 548.966C334.42 540.433 481.981 490.758 492.168 467.902Z" fill="black" stroke="black"/>
                </g>
            </svg>
        </div>
        <div className="signup">
            <div className="stepper">
               <Box sx={{ width: '100%' ,background:'none'}}>
      <Stepper activeStep={activeStep} style={{background:'none',paddingTop:'2rem'}}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </>
      ) : (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>
          <div className='signupcontent'>
              <div>Sign in </div>
              {(() => {
                  
           switch (activeStep+1) {
                case 1:
                  return (
                   <Step1/>
                  )
                case 2:
                  return (
                   <Step2/>
                  )
                  case 3:
                  return (
                   <Step3/>
                  )
                case 4:
                  return (
                   <Step4/>
                  )
              default:
                  return (
                   <Step5/>
                  )
           }
        })()}
          </div>
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
        </>
      )}
    </Box>
            </div>
        </div>
    </div>
  )
}
