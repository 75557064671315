import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import NavBar from "./Components/NavBar/NavBar";
import Footer from "./Components/HomePage/Sections/Footer/Footer";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DistributionImage from "./Assets/distribution.JPG";

export default function Distribution() {
  return (
    <div>
      <NavBar />
      <div
        className="row pt-5"
        style={{
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(96,9,121,1) 56%, rgba(0,212,255,1) 100%)",
        }}
      >
        <div className="col-xs-12 col-sm-12 col-md-12 p-4">
          <div className="p-5">
            <div className="card shadow p-5 text-center ">
              <Typography
                style={{
                  fontSize: "34px",
                  color: "purple",
                  overflowWrap: "normal",
                }}
              >
                Distribution Models{" "}
                <LocalShippingIcon style={{ fontSize: "45px" }} />
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col=md-12 p-5">
          <Typography
            className="p-3"
            style={{
              backgroundColor: "purple",
              color: "white",
              fontSize: "21px",
            }}
          >
            Intermediated Model
          </Typography>
          <div className="card shadow p-3">
            <div className="row p-3 mx-auto w-100">
              <div
                className="col-xs-12 col-sm-3 col-md-3 p-5"
                style={{ backgroundColor: "purple", margin: ".2rem" }}
              >
                <Typography
                  className="text-center mt-3"
                  style={{ color: "white", fontSize: "20px" }}
                >
                  Insurer
                </Typography>
              </div>
              <div
                className="col-xs-12 col-sm-8 col-md-8 p-5"
                style={{
                  border: "1px solid silver",
                  margin: ".2rem",
                  borderRadius: "3px",
                }}
              >
                <Typography>
                  <b>Supply the</b> applicable licence and related governance
                  and compliance oversight, co-develop products and sign off on
                  pricing. Insurer sign Intermediary Agreements with Brokers and
                  UMA.
                </Typography>
              </div>
            </div>
            <div className="row p-3 mx-auto w-100">
              <div
                className="col-xs-12 col-sm-3 col-md-3 p-5"
                style={{ backgroundColor: "#bf71e3", margin: ".2rem" }}
              >
                <Typography
                  className="text-center mt-3"
                  style={{ color: "white", fontSize: "20px" }}
                >
                  Arella UMA
                </Typography>
              </div>
              <div
                className="col-xs-12 col-sm-8 col-md-8 p-5"
                style={{ border: "1px solid silver", margin: ".2rem" }}
              >
                <Typography>
                  <b>Have a</b> full Binder Agreement with the Insurer to enter
                  into, vary or renew policies; determine wording, benefits and
                  premiums under the policy and settle claims. UMA will also
                  perform outsource functions such as quality assurance and
                  screening of brokers for the agency agreement with the
                  insurer. UMA is not permitted to sell direct to customers
                </Typography>
              </div>
            </div>
            <div className="row p-3 mx-auto w-100">
              <div
                className="col-xs-12 col-sm-3 col-md-3 p-5"
                style={{ backgroundColor: "purple", margin: ".2rem" }}
              >
                <Typography
                  className="text-center mt-3"
                  style={{ color: "white", fontSize: "20px" }}
                >
                  Broker
                </Typography>
              </div>
              <div
                className="col-xs-12 col-sm-8 col-md-8 p-5"
                style={{ border: "1px solid silver", margin: ".2rem" }}
              >
                <Typography>
                  <b>Provide non-advice </b>and render intermediary services to
                  the clients, with regard to selling the funeral and other
                  authorised insurance products.
                </Typography>
              </div>
            </div>
            <div className="row p-3 mx-auto w-100">
              <div
                className="col-xs-12 col-sm-3 col-md-3 p-5"
                style={{ backgroundColor: "#bf71e3", margin: ".2rem" }}
              >
                <Typography
                  className="text-center mt-3"
                  style={{ color: "white", fontSize: "20px", margin: ".2rem" }}
                >
                  Client
                </Typography>
              </div>
              <div
                className="col-xs-12 col-sm-8 col-md-8 p-5"
                style={{ border: "1px solid silver", margin: ".2rem" }}
              >
                <Typography>
                  <b>Able to </b>purchase product from the broker; premiums will
                  be collected by the broker or designated collection agency
                  into the Insurer’s bank account. Claims to paid to the
                  nominated beneficiary by the UMA.
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 p-5">
          <Typography
            className="p-3"
            style={{
              backgroundColor: "purple",
              color: "white",
              fontSize: "21px",
            }}
          >
            UMA Broker Network Aspirations
          </Typography>
          <div className="card shadow p-3">
            <ul>
              <li>
                <Typography>
                  Arella is building a wide network of brokers in the Western
                  Cape and Gauteng province.
                </Typography>
              </li>
              <li>
                <Typography>
                  Our expansion into other provinces will also be linked to us
                  increasing our broker network across the country, see the
                  below our projected network growth.
                </Typography>
              </li>
            </ul>
            <div className="mx-auto">
              <img
                src={DistributionImage}
                height="100%"
                style={{ width: "100%", height: "auto" }}
              ></img>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
