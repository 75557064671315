import React from 'react'
import NavBar from '../NavBar/NavBar'
import Footer from './Sections/Footer/Footer'
import mapboxgl from 'mapbox-gl'
import {useRef,useState,useEffect} from 'react'
import { Alert, Button, TextField } from '@mui/material'
import { Call, CallRounded, CheckCircleOutlineOutlined, LocationOnRounded, MailRounded, Send } from '@material-ui/icons'
import { Store } from 'react-notifications-component';
import { useNavigate } from 'react-router-dom'
import formquery from '../../Assets/formquery.svg'
import { Divider } from '@material-ui/core'
import { fontSize } from '@mui/system'

mapboxgl.accessToken= 'pk.eyJ1IjoidnV5YW5pZCIsImEiOiJjbDNpcWZhNjUwZDRtM2pwYzB6OXlpNXE2In0.3s_DQmwI_IMtDEACJmQ11Q'
function ContactUS() {
    const navigate= useNavigate();
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-73.99);
    const [lat, setLat] = useState(40.74);
    const [zoom, setZoom] = useState(10);
    const [sentMessage, setsentMessage] = useState(false)
      /* useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: zoom
    });
  }); */
  const sendingMessage=()=>
        {Store.addNotification({
  title: "Message Sent sucessfully!!!",
  message: "An agent will assist you in less than 15mins",
  type: "success",
  insert: "bottom",
  container: "bottom-center",
  animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  dismiss: {
    duration: 5000,
    onScreen: true
  }
        })}
  return (
    <div>
        <NavBar/>
        <div  className="row pt-5 contactUs">
            <div className="col-lg-12 col-md-12 col-sm-12 mt-5 mb-0">
                <h2 id='top' className='text-center' style={{fontSize:'30px'}}>Get in Touch </h2>
            </div>
            <div className="top col-lg-12 col-md-12 col-sm-12 w-100 row d-flex flex-row w-100 justify-content-evenly mt-5">
               <div className='item col-lg-4 col-md-4 col-sm-4'><div><CallRounded/><h6>Telephone</h6> 078 085 4208</div></div>
               <div className='item col-lg-4 col-md-4 col-sm-4'><div><LocationOnRounded/><h6>Address</h6>Webber Wentzel Building TBE,<br></br>
North Wing, 2nd Floor<br></br>
90 Rivonia Road<br></br>
SANDTON, Johannesburg
</div>
            </div>
            <div className='item col-lg-4 col-md-4 col-sm-4'><div><MailRounded/><h6>Email</h6>info@arellainsurance.com</div></div>
            </div>
            <div id='queryform' className='form col-lg-12 col-md-12 col-sm-12 row justify-content-center mx-1 my-5' style={{height:'auto'}}>
               <h4 className='formqueryheader' style={{textAlign:'center',width:'90%' ,fontSize:"30px"}}>Send us your query </h4> 
             <div className='row col-lg-6 col-md-6 col-sm-6'>
               <div className="col-lg-4 col-md-4 col-sm-4" sx={{height:'50px'}}> <TextField required label='Full Name:' variant="standard"/> </div>
               <div className="col-lg-4 col-md-4 col-sm-4" style={{height:'50px'}}> <TextField  label='Email:' variant="standard"/> </div>
               <div className="col-lg-4 col-md-4 col-sm-4" style={{height:'50px'}}> <TextField required label='Number:' variant="standard"/> </div>
               <div className="col-lg-12 my-5" style={{height:'auto'}}> <TextField multiline fullWidth minRows={3} label='Message'/> </div>
               <div> <Button  variant='contained'  onClick={()=>sendingMessage()} startIcon={<Send/>}>Send</Button></div>
             </div>
            
             <div className='col-lg-6 col-md-6 col-sm-6 p-5'>
               <img src={formquery}/>
             </div>
            </div>

            {/* <div className="col-lg-12 w-75 m-5">
                <div ref={mapContainer} className="map-container" style={{height:'400px'}} />
            </div> */}
        </div>
        <Footer/>
    </div>
  )
}

export default ContactUS