
import * as React from 'react';
import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import EmailIcon from '@mui/icons-material/Email';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TablePagination from '@mui/material/TablePagination';
import Axios from 'axios';
import SwipeableViews from 'react-swipeable-views';


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function PersistentDrawerLeft() {

  function getCurrentDateTime(){

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); 
    let yyyy = today.getFullYear();

    let hh = String(today.getHours()).padStart(2, '0');
    let min = String(today.getMinutes()).padStart(2, '0'); 
    
    today = yyyy + '-' + mm + '-' + dd+" "+hh+":"+min;

    return today
}

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  //------------------------------------------------------------------

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //------------------------------------------------------------------------

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  
  let navigate = useNavigate();
 

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let proxy = 'http://localhost:3000/';
  let SSLproxy = 'https://arellanodesrv.link/';


 let partnerName ='';

  
  const [policies, setPolicies] = React.useState([]);
  
  (function getLoginPartnerName(){
    let nameCookie = document.cookie;
  
    let dummyVariable = nameCookie.substring(nameCookie.lastIndexOf("=")+1).trim();
    console.log(dummyVariable);
   partnerName=dummyVariable;

  })();


function getAllPolicies(){
  
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  
  Axios.post("https://arellanodesrv.link/getAllFuneralPolicyRecords",{
          PARTNERNAME: 'Bonitas'
      }).then((response)=>{
  
          if(policies.length === 0 )
          {
            setPolicies(response.data);
          }

      })
  
  }

  window.onload = getAllPolicies()

function displayPolicyData()
{
  
}


  return (
    <Box sx={{ display: 'flex' }} >
      <CssBaseline />
      <AppBar position="fixed" color='secondary' open={open}  >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Policy Administration
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {['Dashboard', 'Sales', 'Administration', 'Billing', 'Expenses'].map((text, index) => (
            <ListItem button key={text}  onClick={()=>{

                    if(text == 'Sales') { 
                      navigate("/Sales")
                    }
                    if(text == 'Administration') { 
                      navigate("/Administration")
                    }
                    if(text == 'Dashboard') { 
                      navigate("/PartnerPortal")
                    }
                    

                      
                    }}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['Preferences', 'Log Out'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
       
            <h5 >Policy Management</h5>
            <hr/>
           
              <div className='row'> 
                  <div className='col-xs-12 col-sm-6 col-md-6'>
                  <TextField id="outlined-search" size='small' label="Search Policy" type="search" />
                      <IconButton color='secondary'  aria-label="Search" >
                        <SearchIcon />
                      </IconButton>
                  </div>

                  <div className='col-xs-12 col-sm-6 col-md-6' >

                      <IconButton color='secondary' aria-label="Search" >
                        <LocalPrintshopIcon />
                      </IconButton>

                      <IconButton color='secondary' aria-label="Search" >
                        <EmailIcon />
                      </IconButton>

                      <IconButton color='secondary' aria-label="Search" >
                        <SaveIcon/>
                      </IconButton>

                      <IconButton color='secondary' aria-label="Search" >
                        <AddIcon/>
                      </IconButton>

                  </div>
              </div>
              <hr/>
              <div className='row'>
                  <div className='col-xs-12 col-sm-12 col-md-12'>
                  <TableContainer component={Paper} style={{overflowX:'scroll'}} >
                      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Policy No.</TableCell>
                            <TableCell align="left">Main Member</TableCell>
                            <TableCell align="left">Plan</TableCell>
                            <TableCell align="left">Fullname</TableCell>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="left">Email</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                        {policies
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => {
                            return (
                              <TableRow
                                key={row.Funeral_policy_ID}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.Funeral_policy_ID}
                                </TableCell>
                                <TableCell align="left">{row.ID}</TableCell>
                                <TableCell align="left">{row.Cover_Plan}</TableCell>
                                <TableCell align="left">{row.Fullname}</TableCell>
                                <TableCell align="left">{row.Policy_Status}</TableCell>
                                <TableCell align="left">{row.Email}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <div>
                    <TablePagination
                          rowsPerPageOptions={[5, 25, 100]}
                          component="div"
                          count={policies.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </div>
                  
                  </div>
              </div>

              <div className='row'>
                  <div className='col-xs-12 col-sm-12 col-md-12'>

                          <Box sx={{ bgcolor: 'background.paper' }}>
                          <AppBar position="static" color="secondary">
                            <Tabs
                              value={value}
                              onChange={handleChange}
                              indicatorColor="inherit"
                              textColor="inherit"
                              variant="fullWidth"
                              aria-label="full width tabs example"
                            >
                              <Tab label="Update Policy" {...a11yProps(0)} />
                              <Tab label="Policy Finance" {...a11yProps(1)} />
                              <Tab label="Documents" {...a11yProps(2)} />
                            </Tabs>
                          </AppBar>
                          <SwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={value}
                            onChangeIndex={handleChangeIndex}
                          >
                            <TabPanel value={value} index={0} dir={theme.direction}>
                              <div>

                                  <div className='row'>
                                      <div className='col-xs-12 col-sm-3 col-md-3'>
                                            <TextField id="outlined-basic" size='small' label="Outlined" variant="outlined" />
                                      </div>
                                      <div className='col-xs-12 col-sm-3 col-md-3'>
                                            <TextField id="outlined-basic" size='small' label="Outlined" variant="outlined" />
                                      </div>
                                      <div className='col-xs-12 col-sm-3 col-md-3'>
                                            <TextField id="outlined-basic" size='small' label="Outlined" variant="outlined" />
                                      </div>
                                      <div className='col-xs-12 col-sm-3 col-md-3'>
                                            <TextField id="outlined-basic" size='small' label="Outlined" variant="outlined" />
                                      </div>
                                    
                                  </div>

                              </div>
                            </TabPanel>
                            <TabPanel value={value} index={1} dir={theme.direction}>
                              Item Two
                            </TabPanel>
                            <TabPanel value={value} index={2} dir={theme.direction}>
                              Item Three
                            </TabPanel>
                          </SwipeableViews>
                        </Box>

                  </div>
              </div>


             
                        

       
      </Main>
    </Box>
  );
}