import { Box, Icon, LinearProgress } from '@material-ui/core'
import React from 'react'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Assessment, AttachMoneyOutlined, DeckOutlined, Delete } from '@material-ui/icons';

function Card(props) {
    
  return (
    <div className="card">
        <div className="one">
            <div className="number">
                <div>{props.icon}</div>
                <div className="symbol"><h2>{props.symbol}</h2></div>
                <div className="counter">{props.value}</div>
            </div>
            <div className="word">{props.label}</div>
        </div>
        <div className="two">
            <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={props.percent} style={{color:'red'}} />
            </Box>
        </div>
    </div>
  )
}

export default Card