import {React, useState} from 'react'
import sitelogo from '../../Assets/sitelogo.jpg'
import {Link, NavLink} from 'react-router-dom'
import './NavBar.scss'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import CallIcon from '@mui/icons-material/Call';
import BentoIcon from '@mui/icons-material/Bento';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import InfoIcon from '@mui/icons-material/Info';


function NavBar() {
  const [toggle, settoggle] = useState(false)
  const [navlistDisplay, setnavlistDisplay] = useState(false)
  const handletoogle = ()=>{
    settoggle(!toggle);
    setnavlistDisplay(!navlistDisplay)
  }
  return (
  <nav>
  <label  className="logo">
    <NavLink to='/' style={{textDecoration: 'none',color:'black'}}>
      <img src={sitelogo} style={{height: '80px'}}/>
    </NavLink>
    </label>
  <ul className={`nav-list ${navlistDisplay?"":"closed"}`}>
    <li><NavLink to='/AboutUs'> About Us </NavLink></li>
    <li><NavLink to='/Partners'>  Partners </NavLink></li>
    <li><NavLink to='/Distribution'> Information</NavLink>
    <ul className="portals">
      <li className="broker">
        <Link to='/Distribution'>Distribution Model</Link>
      </li>
       <li className="partner">
        <NavLink to='/Capabilities'>Capabilities & Values</NavLink>
      </li>
    </ul>
    </li>
    <li><NavLink to='/ExecutiveTeam'>  Executive Team</NavLink></li>
    <li><NavLink to='/Register'>  Register</NavLink></li>
    <li><NavLink to='/brokersignin'>Portals</NavLink>
    <ul className="portals">
      <li className="broker">
        <NavLink to='/brokersignin'>Broker</NavLink>
      </li>
       <li className="partner">
        <NavLink to='/partnersignin'>Partner</NavLink>
        </li>
    </ul>
    </li>
    <li><NavLink to='/ContactUs'> <HowToRegIcon/> Contact Us</NavLink></li>
  </ul>
  <label className="icon">
    <Button  onClick={handletoogle}>
      <i className={`${toggle?"fa fa-times":"fa fa-bars"}`}></i>
    </Button>
  </label>
</nav> 
  )
}
export default NavBar