
import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';


import Card from './Card'
import { Chart as ChartJS, ArcElement,CategoryScale,LinearScale,BarElement,Title, Tooltip, Legend ,  } from 'chart.js';
import { Doughnut,Bar } from 'react-chartjs-2';
import { Assessment, HomeSharp, Payment, People, ReportProblemRounded } from '@material-ui/icons';
import { LinearProgress } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';



const drawerWidth = 240;


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  let navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

 
    ChartJS.register(ArcElement,CategoryScale,LinearScale,BarElement,Title, Tooltip, Legend);
    const data1 = {
  labels:['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'Arella',
      data: [23,34,35,45,45,56,89],
      backgroundColor: 'purple',
    },
    {
      label: 'Discovery',
      data: [34,78,23,43,89,76,13],
      backgroundColor: 'gold',
    },
    {
      label: 'Sasria',
      data: [34,72,83,34,89,76,13],
      backgroundColor: 'red',
    },
    {
      label: 'Bonitas',
      data: [34,67,23,3,38,48,13],
      backgroundColor: 'blue',
    },
  ],
};
const baroptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Number of New Polices Per Month',
    },
  },
};
    const data = {
  labels: ['Arella', 'Bonitas',  'Discovery', 'Sassria'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 28, 3],
      backgroundColor: [
        'rgba(255, 99, 132, 0.7)',
        'rgba(54, 162, 235, 0.8)',
        'rgba(255, 206, 86, 0.8)',
        'rgba(75, 192, 192, 0.8)',
        
        
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
       
      ],
      borderWidth: 1,
    },
  ],
};
  const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    editable: true,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    editable: true,
  },
  {
    field: 'age',
    headerName: 'Age',
    type: 'number',
    width: 110,
    editable: true,
  },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    valueGetter: (params) =>
      `${params.getValue(params.id, 'firstName') || ''} ${
        params.getValue(params.id, 'lastName') || ''
      }`,
  },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];




  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" color='secondary' open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {['Dashboard', 'Sales', 'Administration', 'Billing', 'Expenses'].map((text, index) => (
            <ListItem button key={text} onClick={()=>{

                 if(text == 'Sales') { 
                    navigate("/Sales")
                  }
                  if(text == 'Administration') { 
                    navigate("/Administration")
                  }
                  if(text == 'Dashboard') { 
                    navigate("/PartnerPortal")
                  }

                    
            }}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {['Preferences', 'Log Out'].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Typography paragraph>
            Statistics
         </Typography>
         <hr/>

         <div className="analytics">
       <div className="topcards">
           <Card icon={<Assessment style={{color:'red'}}/>} value={150} label={"Claims"} symbol="" percent={4}/>
           <Card icon={<People style={{color:'blue'}}/>} value={560} label={"Policies"} symbol={""} percent={55}/>
           <Card icon={<ReportProblemRounded style={{color:'green'}}/>} value={57} label={"New Applications"} symbol={""} percent={45}/>
           <Card icon={<Payment style={{color:'gold'}}/>} value={600} label={"Payouts"} color={"blue"} percent={78}/>
       </div>
       <div className="chart">
           <div style={{position:'relative',width:'100%',paddingLeft:'2rem'}} className=' row d-flex flex-row'>
             
             <Bar options={baroptions} data={data1} className='barchart'/>
         </div>
       </div>
       <div className="sideinfo">
           <div className="insurance">
               <h4>Insurances</h4>
               <hr style={{margin:'0 1rem'}}/>
               <div className="indicator mt-3">
                   <p>Arella</p>
                   <LinearProgress variant="determinate" value={35}/>
                   <h6>45/100</h6>
               </div>
               <div className="indicator">
                   <p>Discovery</p>
                   <LinearProgress variant="determinate" value={35}/>
                   <h6>56/90</h6>
               </div>
               <div className="indicator">
                   <p>Bonitas</p>
                   <LinearProgress variant="determinate" value={35}/>
                   <h6>56/98</h6>
               </div>
               <div className="indicator">
                   <p>Sassria</p>
                   <LinearProgress variant="determinate" value={35}/>
                   <h6>90/100</h6>
               </div>
               <div className="donut">
                   <Doughnut data={data} />
               </div>
           </div>
       </div>
       <div className='tables'>
           <div className="table1">
                <h5>Claims Records</h5>
                <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        checkboxSelection
        disableSelectionOnClick
      />
    </div>
           </div>
       <div className="table2">
           <h5>Policies Records</h5>
           <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        checkboxSelection
        disableSelectionOnClick
      />
      </div>
       </div>
       </div>
       
   </div>



        
      </Main>
    </Box>
  );
}