import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NavBar from "./Components/NavBar/NavBar";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { Form } from 'react-bootstrap';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';


const steps = ['About yourself', ' Simple questions', 'Choosing a plan for you','Nominate Beneficiaries','Payment Details'];
let depArr = []

export default function SignUpPolicy() {

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
  
    const isStepOptional = (step) => {
      return step === 1;
    };
  
    const isStepSkipped = (step) => {
      return skipped.has(step);
    };
  
    const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };

    const [maritalStatus, setMarittalStatus] = React.useState('');

    const handleChangeMS = (event) => {
      setMarittalStatus(event.target.value);
    };

    const [Correspondence, setCorrespondence] = React.useState('');

    const [gender, setGender] = useState('');

    const handleChangeCOR = (event) => {
      setGender(event.target.value);
    };


    const [optionPlan, setOptionPlan] = useState('');
    const [optionPayout, setOptionPayout] = useState('30 000');
    const [optionPlanPremium, setOptionPlanPremium] = useState('30');
    const [otherDependants, setOtherDependants] = useState(false);
    const[paymentMethod, setPaymentMethod] = useState('');
    const[paymentMethodDisplay, setPaymentMethodDisplay] = useState(false);
    const[bankingDetailsDisplay, setBankingDetailsDisplay] = useState(false);
    const [firstname, setFirstname] = useState('');
    const [lastName, setLastName] = useState('');
    const [dob, setDob] = useState('');
    const [contact, setContact] = useState('');
    const [address, setAddress] = useState('');
    const [age, setAge] = useState('');
    const [idNumber, setIdNumber] = useState('');
    const [email, setEmail] = useState('');
   


    const [dependantID, setDependantID] = useState('');
    const [dependantFullname, setDependantFullname] = useState('');
    const [dependantContact, setDependantContact] = useState('');
    const [dependantAddress, setDependantAddress] = useState('');
    const [dependantAge, setDependantAge] = useState('');
    const [ageType, setAgeType] = useState('');

    const handleChangeDAgeType = (event) => {
      setAgeType(event.target.value);
    };


    const [ beneficiaryID , setBeneficiaryID] = useState('');
    const [ beneficiaryName , setBeneficiaryName] = useState('');
    const [ beneficiaryContact , setBeneficiaryContact] = useState('');
    const [ beneficiaryAddress, setBeneficiaryAddress] = useState('');
    


    const [paymentDate, setPaymentDate] = useState('');
    const [bankName, setBankName] = useState('');
    const [branchCode  , setBranchCode] = useState('');
    const [ accountNNumber , setAccountNumber] = useState('');
    const [ accountType , setAccountType] = useState('');

   
 let line = ''
   
    
const addDependantsOnSignUp = function(){

  depArr.push(dependantID);
  depArr.push(dependantFullname);
  depArr.push(dependantContact);
  depArr.push(dependantAddress);
  depArr.push(ageType);
  depArr.push(dependantAge);
  line = "#" + line + "_" + depArr
  console.log(line);

}


    const displayStep1 = function(){

      return(
        <div className='' >

            <div className='row '>
                <div className='col-xs-12 col-sm-8 col-md-8 mx-auto'>
                    <div className='container '>
                        <div className='card shadow'>

                                 <div className='lead p-2' style={{background:'purple'}}>
                                    <Typography style={{color:'white'}}>Step 1 - Personal Particulars</Typography>
                                    <p style={{fontSize:'12px', color:'white'}}>(Please provide only accurate and no later than 3 months information)</p>
                                </div>

                                  <div className='p-3'>

                                  <div className='row'>
                                        <div className='col-xs-12 col-sm-6 col-md-6'>
                                            <TextField
                                                  id="outlined-helperText"
                                                  label="First Name"
                                                  placeholder="John"
                                                  helperText="According to RSA ID document"
                                                  required={true}
                                                  size='small'
                                                  onChange={(event)=>{
                                                      setFirstname(event.target.value);
                                                  }}  
                                                />
                                        </div>
                                        <div className='col-xs-12 col-sm-6 col-md-6'>
                                            <TextField
                                                  id="outlined-helperText"
                                                  label="Last Name"
                                                  placeholder='Sethemba'
                                                  helperText="According to RSA ID document"
                                                  required={true}
                                                  size='small'
                                                  onChange={(event)=>{
                                                    setLastName(event.target.value);
                                                  }}
                                                />
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-xs-12 col-sm-6 col-md-6'>
                                            <TextField
                                                  id="outlined-helperText"
                                                  label="Your RSA Identity number"
                                                  placeholder="13-digit ID "
                                                  helperText="According to RSA ID document"
                                                  required={true}
                                                  size='small'
                                                  onChange={(event)=>{
                                                        setIdNumber(event.target.value);
                                                  }}  
                                                />
                                        </div>
                                        <div className='col-xs-12 col-sm-6 col-md-6'>
                                            <TextField
                                                  id="outlined-helperText"
                                                  label="Cellphone Contact"
                                                  placeholder='032 453 7878'
                                                  required={true}
                                                  size='small'
                                                  onChange={(event)=>{
                                                      setContact(event.target.value);
                                                  }}
                                                />
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-xs-12 col-sm-12 col-md-12'>
                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Physical Address"
                                            multiline
                                            rows={4}
                                            placeholder="5 Moffat Dr, Ballito Business Park, Ballito, Dolphin Coast, 4420"
                                            sx={{width:'100%'}}
                                            required={true}
                                            size='small'
                                            onChange={(event)=>{
                                                setAddress(event.target.value);
                                            }}    
                                          />
                                        </div>
                                      
                                    </div>

                                  </div>

                        </div>
                    </div>
                </div>
            </div>


        </div>
      );
    }

    const displayStep2 = function(){

      return(
        <div className='' >

            <div className='row'>
                <div className='col-xs-12 col-sm-8 col-md-8 mx-auto'>
                    <div className='container'>
                              <div className='card shadow '>

                              <div className='lead p-2' style={{background:'purple'}}>
                                    <Typography style={{color:'white'}}>Step 2 - Basic information</Typography>
                                    <p style={{fontSize:'12px', color:'white'}}>(Please provide only accurate and no later than 3 months information)</p>
                                </div>

                                  <div className='p-3'>
                                               

                                            <div className='row'>
                                        <div className='col-xs-12 col-sm-6 col-md-6'>
                                              <FormControl fullWidth>
                                                  <InputLabel id="demo-simple-select-label">Marital Status</InputLabel>
                                                  <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={maritalStatus}
                                                    size='small'
                                                    label="Marital Status"
                                                    onChange={(event)=>{
                                                      event.preventDefault();
                                                      handleChangeMS()
                                                      setMarittalStatus(event.target.value);
                                                      }}
                                                    
                                                  >
                                                    <MenuItem value={'Single'}>Single</MenuItem>
                                                    <MenuItem value={'Married'}>Married</MenuItem>
                                                    <MenuItem value={'Divorced'}>Divorced</MenuItem>
                                                  </Select>
                                                </FormControl>
                                        </div>
                                        <div className='col-xs-12 col-sm-6 col-md-6'>
                                        <FormControl fullWidth>
                                                  <InputLabel id="demo-simple-select-label">Correspondence</InputLabel>
                                                  <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={Correspondence}
                                                    size='small'
                                                    label="Correspondence"
                                                    onChange={(event)=>{
                                                      handleChangeCOR()
                                                      setCorrespondence(event.target.value);
                                                      }}
                                                  >
                                                    <MenuItem value={'SMS'}>SMS</MenuItem>
                                                    <MenuItem value={'Email'}>Email</MenuItem>
                                                  
                                                  </Select>
                                                </FormControl>
                                        </div>
                                    </div>

                                    <div className='row mt-4'>
                                        <div className='col-xs-12 col-sm-6 col-md-6'>
                                        <FormControl fullWidth>
                                                  <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                                                  <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={gender}
                                                    size='small'
                                                    label="Gender"
                                                    onChange={handleChangeCOR}
                                                  >
                                                    <MenuItem value={'SMS'}>SMS</MenuItem>
                                                    <MenuItem value={'Email'}>Email</MenuItem>
                                                  
                                                  </Select>
                                                </FormControl>
                                        </div>
                                        <div className='col-xs-12 col-sm-6 col-md-6'>
                                      
                                        <TextField
                                                  id="outlined-helperText"
                                                  label="Email"
                                                  placeholder="someone@somewhere.com "
                                                  required={true}
                                                  size='small'
                                                  onChange={(event)=>{
                                                      setEmail(event.target.value);
                                                  }}  
                                                />
                                          
                                        
                                        </div>
                                    </div>

                                    <div className='row mt-1'>
                                        <div className='col-xs-12 col-sm-6 col-md-6'>
                                        <span> <Form.Label>Date of Birth</Form.Label></span>
                                              <Form.Control type="date" name="dob" placeholder="Date of Birth" onChange={
                                                (event)=>{
                                                    setDob(event.target.value);
                                                }
                                              }  />
                                        
                                        </div>
                                    </div>
                                  </div>
                            
                              </div>
                    </div>
                


                    
                </div>
            </div>


        </div>
      );
    }

    const displayOtherDependantParticulars = function(){
      return(
    
        <div className='' >
    
            <div className='row'>
                <div className='col-xs-12 col-sm-8 col-md-8 mx-auto'>
                    <div className='container'>
                              <div className='card shadow '>
                                <div className='lead p-3' style={{background:'purple'}}>
                                    <Typography style={{color:'white'}}>Additional Dependants</Typography>
                                </div>

                                <div className='p-3'>
                                        <div className='row mt-4'>
                                            <div className='col-xs-12 col-sm-6 col-md-6'>
                                            <TextField
                                                      id="outlined-helperText"
                                                      label="Dependant ID No"
                                                      placeholder="13-digit RSA ID"
                                                      required={true}
                                                      size='small'
                                                      onChange={(event)=>{
                                                          setDependantID(event.target.value);
                                                      }}
                                                    />
                                              
                                              
                                            </div>
                                           
                                        </div>

                                        <div className='row mt-4'>
                                            <div className='col-xs-12 col-sm-6 col-md-6'>
                                           
                                            <TextField
                                                  id="outlined-multiline-static"
                                                  label="Physical Address"
                                                  multiline
                                                  rows={4}
                                                  placeholder="5 Moffat Dr, Ballito Business Park, Ballito, Dolphin Coast, 4420"
                                                  sx={{width:'100%'}}
                                                  required={true}
                                                  size='small'
                                                  onChange={(event)=>{
                                                      setDependantAddress(event.target.value);
                                                  }}
                                                />


                                            </div>
                                            <div className='col-xs-12 col-sm-6 col-md-6'>
                                          
                                            <TextField
                                                      id="outlined-helperText"
                                                      label="Fullname"
                                                      placeholder="Nathan"
                                                      required={true}
                                                      size='small'
                                                      className='mb-4'
                                                      onChange={(event)=>{
                                                            setDependantFullname(event.target.value);
                                                        }}
                                                    />

                                            <TextField
                                                      id="outlined-helperText"
                                                      label="Mobile\Home Contact No"
                                                      placeholder="031-233 4543"
                                                      required={true}
                                                      size='small'
                                                      onChange={(event)=>{
                                                          setDependantContact(event.target.value);
                                                        }}
                                                    />
                                            </div>
                                        </div>

                                        <div className='row mt-4'>
                                            <div className='col-xs-12 col-sm-6 col-md-6'>
                                            <FormControl fullWidth>
                                                      <InputLabel id="demo-simple-select-label">Adult\Child</InputLabel>
                                                      <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={ageType}
                                                        size='small'
                                                        label="Adult\Child"
                                                        onChange={ handleChangeDAgeType}
                                                      >
                                                        <MenuItem value={'Adult'}>Adult</MenuItem>
                                                        <MenuItem value={'Child'}>Child</MenuItem>
                                                      
                                                      </Select>
                                                    </FormControl>
                                            </div>
                                            <div className='col-xs-12 col-sm-6 col-md-6'>
                                          
                                            <TextField
                                                      id="outlined-helperText"
                                                      label="Dependant Age"
                                                      placeholder="23 "
                                                      required={true}
                                                      size='small'
                                                      onChange={(event)=>{
                                                          setDependantAge(event.target.value);
                                                      }}
                                                    />
                                              
                                            
                                            </div>
                                        </div>

                                        <div className='row mt-4'>
                                           <div className='col-xs-12 col-sm-6 col-md-6'>
                                              <Button className='text-center' size='small' onClick={()=>{
                                                          addDependantsOnSignUp()}} color='secondary' variant="contained" endIcon={<AddIcon />}>
                                                Dependant
                                              </Button>
                                            </div>
                                        </div>

                                </div>
                              </div>
                    </div>
                </div>
            </div>
    
       </div>         
    
      );
    }

    const displayStep3 = function(){

      return(
        <div className='' >

            <div className='row mb-2'>
                <div className='col-xs-12 col-sm-8 col-md-8 mx-auto'>
                    <div className='container'>
                              <div className='card shadow'>

                              <div className='lead p-2' style={{background:'purple'}}>
                                    <Typography style={{color:'white'}}>Step 3</Typography>
                                    <p style={{fontSize:'12px', color:'white'}}>(You may be required to provide particulars of other dependants based upon plan choice)</p>
                                </div>

                                <div className='p-3'>
                                  

                              <div className='row mt-1'>
                          <div className='col-xs-12 col-sm-6 col-md-6'>
                               <p className='lead'><b>Policy Option <ArrowRightAltIcon/></b></p>
                          </div>
                          <div className='col-xs-12 col-sm-6 col-md-6'>
                          <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Choose your plan</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={optionPlan}
                                      size='small'
                                      label="Choose your plan"
                                      onChange={(event)=>{
                                        
                                        setOptionPlan(event.target.value);

                                          if(event.target.value==='Option 1')
                                          {
                                                setOptionPayout('10 000');
                                                setOptionPlanPremium('30');
                                                setOtherDependants(false);
                                          }
                                          if(event.target.value==='Option 2')
                                          {
                                            setOptionPayout('20 000');
                                                setOptionPlanPremium('65');
                                                setOtherDependants(true);
                                          }
                                          if(event.target.value==='Option 3')
                                          {
                                            setOptionPayout('60 000');
                                                setOptionPlanPremium('95');
                                                setOtherDependants(true);
                                          }

                                      }}
                                    >
                                      <MenuItem value={'Option 1'}>Option 1</MenuItem>
                                      <MenuItem value={'Option 2'}>Option 2</MenuItem>
                                      <MenuItem value={'Option 3'}>Option 3</MenuItem>
                                     
                                    </Select>
                                  </FormControl>
                          </div>
                       </div>

                       <div className='row mt-4'>
                          <div className='col-xs-12 col-sm-6 col-md-6'>
                          <p className='lead'>Monthly Premium <ArrowRightAltIcon/> 
                                <h4 style={{color:'purple'}}>R {optionPlanPremium}</h4>
                          </p>
                          </div>
                          <div className='col-xs-12 col-sm-6 col-md-6'>
                         
                          <p className='lead'>Payout Amount <ArrowRightAltIcon/>
                              <h4 style={{color:'purple'}}>R {optionPayout}</h4>
                          </p>
                            
                          </div>
                       </div>
                                </div>

                    
                              </div>
                    </div>
                
                </div>
            </div>

              {otherDependants ? displayOtherDependantParticulars():null}

        </div>
      );
    }


    const displayStep4 = function(){

      return(
                  <div className='' >

                            <div className='row mb-2'>
                                <div className='col-xs-12 col-sm-8 col-md-8 mx-auto'>
                                    <div className='container'>
                                              <div className='card shadow'>

                                              <div className='lead p-2' style={{background:'purple'}}>
                                                    <Typography style={{color:'white'}}>Step 4 - Adding your beneficiary</Typography>
                                                    <p style={{fontSize:'12px', color:'white'}}>(You may be required to provide particulars of beneficiaries)</p>
                                                </div>

                                                <div className='p-3'>
                                                  

                                              <div className='row mt-1'>
                                          <div className='col-xs-12 col-sm-6 col-md-6'>
                                                  
                                                   <TextField
                                                      id="outlined-helperText"
                                                      label="Beneficiary ID No"
                                                      placeholder="13-digit RSA ID"
                                                      required={true}
                                                      size='small'
                                                      onChange={(event)=>{
                                                          setBeneficiaryID(event.target.value);
                                                      }}
                                                    />

                                                     <TextField
                                                      id="outlined-helperText"
                                                      label="Beneficiary Fullname"
                                                      placeholder="Nathan"
                                                      required={true}
                                                      className='mt-2'
                                                      size='small'
                                                      onChange={(event)=>{
                                                          setBeneficiaryName(event.target.value);
                                                      }}
                                                    />

                                                    <TextField
                                                      id="outlined-helperText"
                                                      label="Mobile\Home Contact"
                                                      placeholder="1032 923 4356"
                                                      required={true}
                                                      size='small'
                                                      className='mt-2'
                                                      onChange={(event)=>{
                                                          setBeneficiaryContact(event.target.value);
                                                      }}
                                                    />
                                          </div>
                                          <div className='col-xs-12 col-sm-6 col-md-6'>
                                          <TextField
                                                id="outlined-multiline-static"
                                                label="Physical Address"
                                                multiline
                                                rows={5}
                                                placeholder="5 Moffat Dr, Ballito Business Park, Ballito, Dolphin Coast, 4420"
                                                sx={{width:'100%'}}
                                                required={true}
                                                size='small'
                                                onChange={(event)=>{
                                                    setBeneficiaryAddress(event.target.value)
                                                }}  
                                              />
                                          </div>
                                      </div>

                                      <div className='row mt-3'>
                                          <div className='col-6'>
                                               <Button className='text-center' size='small' color='secondary' variant="contained" endIcon={<AddIcon />}>
                                                Beneficiary
                                              </Button>
                                          </div>
                                      </div>

                                   
                                </div>

                    
                              </div>
                    </div>
                
                </div>
            </div>
        </div>
      );
    }

    const displayEFTDetails = function(){

      return(
        <div className='' >

            <div className='row mb-2'>
                <div className='col-xs-12 col-sm-8 col-md-8 mx-auto'>
                    <div className='container'>
                              <div className='card shadow'>

                                <div className='lead p-2' style={{background:'purple'}}>
                                    <Typography style={{color:'white'}}>Banking Details</Typography>
                                    <p style={{fontSize:'12px', color:'white'}}>(Please provide only accurate and no later than 3 months information)</p>
                                </div>

                                <div className='p-3'>
                                        <div className='row mt-1'>
                                          <div className='col-xs-12 col-sm-6 col-md-6'>
                                          <TextField
                                                      id="outlined-helperText"
                                                      label="Bank Name"
                                                      placeholder="Standard Bank SA"
                                                      required={true}
                                                      size='small'
                                                      className='mt-2'
                                                      onChange={(event)=>{
                                                            setBankName(event.target.value);
                                                      }}
                                                    />
                                          </div>
                                          <div className='col-xs-12 col-sm-6 col-md-6'>
                                          <TextField
                                                      id="outlined-helperText"
                                                      label="Branch Code"
                                                      placeholder="510005"
                                                      required={true}
                                                      size='small'
                                                      className='mt-2'
                                                      onChange={(event)=>{
                                                          setBranchCode(event.target.value);
                                                      }}
                                                    />
                                          </div>
                                        </div>
                                        <div className='row mt-2'>
                                          <div className='col-xs-12 col-sm-6 col-md-6'>
                                          <TextField
                                                      id="outlined-helperText"
                                                      label="Account Number"
                                                      placeholder="Standard Bank SA"
                                                      required={true}
                                                      size='small'
                                                      className='mt-2'
                                                      onChange={(event)=>{
                                                          setAccountNumber(event.target.value);
                                                      }}
                                                    />
                                          </div>
                                          <div className='col-xs-12 col-sm-6 col-md-6'>
                                          <TextField
                                                      id="outlined-helperText"
                                                      label="Account Type"
                                                      placeholder="510005"
                                                      required={true}
                                                      size='small'
                                                      className='mt-2'
                                                      onChange={(event)=>{
                                                          setAccountType(event.target.value);
                                                      }}
                                                    />
                                          </div>
                                        </div>
                                </div>

                              </div>

                            </div>
                         </div>
                     </div>
                </div>
      );
    }

    const displayStep5 = function(){

      return(
        <div className='' >

            <div className='row mb-2'>
                <div className='col-xs-12 col-sm-8 col-md-8 mx-auto'>
                    <div className='container'>
                              <div className='card shadow'>

                              <div className='lead p-2' style={{background:'purple'}}>
                                    <Typography style={{color:'white'}}>Final Step</Typography>
                                    <p style={{fontSize:'12px', color:'white'}}>(Please provide only accurate and no later than 3 months information)</p>
                                </div>

                                <div className='p-3'>
                                  

                              <div className='row mt-1'>
                          <div className='col-xs-12 col-sm-6 col-md-6'>
                               <Typography> Choose your method of payment <ArrowRightAltIcon style={{color:'purple'}}/></Typography>  
                          </div>
                          <div className='col-xs-12 col-sm-6 col-md-6'>
                                 <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Method of payment</InputLabel>
                                    <Select
                                      labelId="demo-simple-select-lbel"
                                      id="demo-simple-select"
                                      value={paymentMethod}
                                      size='small'
                                      label="Method of payment"
                                      onChange={(event)=>{

                                        setPaymentMethod(event.target.value);

                                        if(event.target.value==='EFT'){
                                          setBankingDetailsDisplay(true)
                                        }
                                        if(event.target.value==='Cash'){
                                          setBankingDetailsDisplay(false)
                                        }
                                        
                                        }}

                                       
                                    >
                                      <MenuItem value={'Cash'}>Cash</MenuItem>
                                      <MenuItem value={'EFT'}>EFT</MenuItem>
                                     
                                    </Select>
                                  </FormControl>
                          </div>
                       </div>

                       <div className='row mt-4'>
                       <div className='col-xs-12 col-sm-6 col-md-6'>
                            <div className='lead'>
                                <Typography><b>Select your collection date <ArrowRightAltIcon style={{color:'purple'}}/></b></Typography>
                            </div>
                          
                              
                          </div>
                          <div className='col-xs-12 col-sm-6 col-md-6'>
                              <Form.Control type="date" name="dob" placeholder="Date of Birth" onChange={(event)=>{
                                    setPaymentDate(event.target.value);
                              }}/>
                          </div>
                       </div>
                                </div>

                    
                              </div>
                    </div>
                
                </div>
            </div>

              {bankingDetailsDisplay ? displayEFTDetails():null}

        </div>
      );
    }


    
  return (
    <div>

        <NavBar/>

      
   <br/>
        <div className='container'>
        <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
          
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            Congradulations
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>  </Typography>
          <Box sx={{   pt: 2 }}>
            
              { activeStep === 0  ?
                displayStep1()
                :null
              }
              {
                activeStep === 1  ?
                displayStep2()
                :null
                }
                {
                activeStep === 2  ?
                displayStep3()
                :null
                }
                {
                activeStep === 3  ?
                displayStep4()
                :null
                }
                {
                activeStep === 4  ?
                displayStep5()
                :null
                }

                <div className='row'>
                    <div className='col-xs-12 col-sm-6 col-md-6'>
                                <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                       
                        >
                          Back
                        </Button>
                      
                    </div>
                    <div className='col-xs-12 col-sm-6 col-md-6' >
                        <Button align='right' sx={{float:'right'}}  onClick={handleNext} >
                          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </div>
                </div>
                 
           
          </Box>
        </React.Fragment>
      )}
    </Box>
        </div>



    </div>
  )
}
