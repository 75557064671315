import React, { useState, useEffect, useRef } from 'react'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import NavBar from "./Components/NavBar/NavBar";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { Form } from 'react-bootstrap';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import { AppBar, Grid } from '@mui/material';
import lottie from 'lottie-web';
import Online from './Assets/online.json'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import validateEmail from './Components/Portals/validations';
import validator from 'validator'
import { Email } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';



const steps = ['Why should you Sign Up', ' Simple questions', 'Complete'];


export default function Register() {
  const navigate= useNavigate();

  const container = useRef(null);
    
  const [loginData, setLoginData]  = useState({fullname:'',username:'',email:'',cellphone:'',password:'',confirmPassword:''});
  const [fullname, setfullname] = useState('')
  const [fullnameError, setfullnameError] = useState(false)
  const [username, setusername] = useState('')
  const [usernameError, setusernameError] = useState(false)
  const [email, setemail] = useState('')
  const [emailError, setemailError] = useState(false)
  const [cellphone, setcellphone] = useState('')
  const [cellphoneError, setcellphoneError] = useState(false)
  const [password, setpassword] = useState('')
  const [passwordError, setpasswordError] = useState(false)
  const [confirmPassword, setconfirmPassword] = useState('')
  const [confirmPasswordError, setconfirmPasswordError] = useState(false)
  const [nomatchPassword, setnomatchPassword] = useState(false)
const [showPassword, setshowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setshowPassword(!showPassword)
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
 
   useEffect( ()=>{

    lottie.loadAnimation({
        container: container.current,
        renderer:'svg',
        loop:true,
        autoplay:true,
        animationData:Online
    })
    },[])
    const [currentPage, setcurrentPage] = useState(1)

    const Page1 = function(){
     
        return(
           <div className='container'>
                 <div className='row'>
                    <div className='col-xs-12 col-md-6 col-sm-6 mx-auto'>
                         <div className='card shadow mb-3'>
                                 <div className='lead p-2' style={{background:'purple'}}>
                                    <Typography style={{color:'white'}}>Register yourself to use our online services</Typography>
                                    <p style={{fontSize:'12px', color:'white'}}>(You will be able to perform flexible management of your policies with us)</p>
                                </div>
                                <div className='p-3'>
                                  <div className='row'>
                                      <div className='  col-xs-12 col-md-12 col-sm-12' ref={container}>

                                      </div>
                                  </div>
                                  <Typography className='text-center' style={{color:'purple', fontSize:'16px'}}>
                                       The many benefits of online business include: global access, 24 hours a day, 7 days a week. Improved client service through greater flexibility
                                  </Typography>
                                  <div className='row mb-2'>
                                      <div className='col-xs-12 col-sm-6 col-md-6'>
                                                  
                                        
                                      </div>
                                      <div className='col-xs-12 col-sm-6 col-md-6' >
                                          <Button align='right' sx={{float:'right'}} variant='contained' onClick={()=>setcurrentPage(2)} >
                                             next
                                          </Button>
                                      </div>
                                  </div>
                                </div>
                        </div>
                    </div>
                </div>
           </div>

        )
    }

   const Page2 = function(){
   
    const checkValidationsBeforeSubmit=()=>{
      if(fullname===''){
        setfullnameError(true)
        return false;
      }
      else{
        setfullnameError(false)
        
      }
      if(username===''){
        setusernameError(true)
         return false;
      }else(
        setusernameError(false)
      )
      if(!validator.isEmail(email)){
        setemailError(true)
         return false;
      }else{
        setemailError(false)
      }
      if(!validator.isMobilePhone(cellphone)){
        setcellphoneError(true)
         return false;
      }else{
        setcellphoneError(false)
      }
      if(password===''){
        setpasswordError(true)
         return false;
      }
      else{
        setpasswordError(false)
      }
      if(confirmPassword===''){
        setconfirmPasswordError(true)
         return false;
      }
      else{
        setconfirmPasswordError(false)
      }
      return true
    }
    const handleSubmit=()=>{
      if(checkValidationsBeforeSubmit())
        setcurrentPage(3);
    }
    useEffect(() => {
     let valide =  checkValidationsBeforeSubmit();
    }, [fullname,cellphone,email,username,confirmPassword,password])
    
   return(
      <div className='container w-50'>
          <div className='card shadow mb-3 d-flex flex-column justify-content-center align-items-center '>
                            <div className='lead p-2 w-100' style={{background:'purple'}}>
                               <Typography style={{color:'white'}}>Complete Registration form below</Typography>
                               <p style={{fontSize:'12px', color:'white'}}>(You will be able to perform flexible management of your policies with us)</p>
                           </div>
                           <div className='p-5'>
                             <Grid container spacing={2}>
                               <Grid item xs={6}>
                                 <TextField id='name' label="Full Name" error={fullnameError} required variant='standard' values={fullname} onChange={(e)=>setfullname(e.target.value)} />
                              </Grid>
                                 <Grid item xs={6}>
                                  <TextField label="Username"  variant='standard'  error={usernameError} required values={username} onChange={e=>setusername(e.target.value)} />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField label="Email"  variant='standard'  error={emailError} values={email} onChange={e=>setemail(e.target.value)} />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField label="Cellphone"  variant='standard' error={cellphoneError} values={cellphone} onChange={e=>setcellphone(e.target.value)} />
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField id="input-with-icon-textfield" error={passwordError} type={showPassword ? 'text' : 'password'} label="Password" onChange={e=>setpassword(e.target.value)} InputProps={{endAdornment: ( <InputAdornment position="end">
                                     <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                       {showPassword ? <VisibilityOff /> : <Visibility />}
                                       </IconButton></InputAdornment>),}}variant="standard"/>
                                   </Grid>
                                <Grid item xs={6}>
                                  <TextField id="input-with-icon-textfield"  type={showPassword ? 'text' : 'password'} label="Confirm Password" error={confirmPasswordError} onChange={e=>setconfirmPassword(e.target.value)} InputProps={{endAdornment: ( <InputAdornment position="end">
                                     <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                       {showPassword ? <VisibilityOff /> : <Visibility />}
                                       </IconButton></InputAdornment>),}}variant="standard"/></Grid>
                            </Grid>
                             <div className='row mb-2 mt-2'>
                                 <div className='col-xs-12 col-sm-6 col-md-6'>
                                             <Button
                                       color="inherit"
                                       variant='contained' onClick={()=>setcurrentPage(1)}
                                     >
                                       Back
                                     </Button>
                                 </div>
                                 <div className='col-xs-12 col-sm-6 col-md-6' >
                                     <Button align='right' sx={{float:'right'}} variant='contained'onClick={()=>handleSubmit()} >
                                       Next
                                     </Button>
                                 </div>
                             </div>
                           </div>
          </div>
      </div>
   )
    }
    const Page3 = function(){
       return(
           <div className='container'>
                 <div className='row'>
                    <div className='col-xs-12 col-md-6 col-sm-6 mx-auto'>
                         <div className='card shadow mb-3'>
                                 <div className='lead p-2' style={{background:'purple'}}>
                                    <Typography style={{color:'white'}}>Register yourself to use our online services</Typography>
                                    <p style={{fontSize:'12px', color:'white'}}>(You will be able to perform flexible management of your policies with us)</p>
                                </div>
                                <div className='p-3'>
                                  <div className='row'>
                                      <div className='  col-xs-12 col-md-12 col-sm-12' ref={container}>
                                      </div>
                                  </div>
                                  <Typography className='text-center m-5' style={{color:'purple', fontSize:'16px'}}>
                                       Thank you for registering with us !!!
                                  </Typography>
                                  <div className='row mb-2'>
                                      <div className='col-xs-12 col-sm-6 col-md-6'>
                                             <Button align='right' sx={{float:'left'}} variant='contained' onClick={()=>navigate('/')} >
                                             Home
                                          </Button>     
                                      </div>
                                      <div className='col-xs-12 col-sm-6 col-md-6'>
                                             <Button align='right' sx={{float:'right'}} variant='contained' onClick={()=>navigate("/partnersignin")} >
                                            Sign In
                                          </Button>     
                                      </div>
                                  </div>
                                </div>
                        </div>
                    </div>
                </div>
           </div>

        )
    } 
   const Pages= {
      1: Page1(),
      2:Page2(),
      3:Page3()
    }
    return (
    <div>
        <NavBar/>
        <div className='container pt-5' style={{height:'fit-content'}}>
          <div className='d-flex pt-4'>
             {currentPage===1?Pages[1]:""}
             {currentPage===2?Pages[2]:""}
             {currentPage===3?Pages[3]:""}
          </div>
        
        </div>
    </div>
  )
}
