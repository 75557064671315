import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import NavBar from "./Components/NavBar/NavBar";
import Footer from "./Components/HomePage/Sections/Footer/Footer";
import LinkIcon from "@mui/icons-material/Link";

export default function Capabilities() {
  return (
    <div>
      <NavBar />
      <div
        className="row pt-5"
        style={{
          background:
            "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(96,9,121,1) 56%, rgba(0,212,255,1) 100%)",
        }}
      >
        <div className="col-xs-12 col-sm-12 col-md-12 p-4">
          <div className="p-5">
            <div className="card shadow p-5 text-center">
              <div
                style={{
                  fontSize: "2vw",
                  color: "purple",
                  overflowWrap: "normal",
                }}
              >
                <h3 className="w-100">Our Capabilities & Value Chain</h3>
                <LinkIcon style={{ fontSize: "45px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 p-5">
          <Typography
            className="p-3"
            style={{
              backgroundColor: "purple",
              color: "white",
              fontSize: "21px",
            }}
          >
            Our Business Capabilities
          </Typography>
          <div className="card shadow p-5">
            <div className="row">
              <div
                className="col-xs-12 my-2 col-sm-4 col-md-4"
                style={{ height: "unset" }}
              >
                <div
                  className="card p-3 text-center"
                  style={{ border: "2px solid purple", height: "100%" }}
                >
                  <h6>
                    <b>Deal Structuring</b>
                  </h6>
                </div>
              </div>
              <div
                className="col-xs-12 my-2 col-sm-4 col-md-4"
                style={{ height: "unset" }}
              >
                <div
                  className="card p-3 text-center"
                  style={{ border: "2px solid purple", height: "100%" }}
                >
                  <h6>
                    <b>Product Development, Market Intelligence and Insights</b>
                  </h6>
                </div>
              </div>
              <div
                className="col-xs-12 my-2 col-sm-4 col-md-4"
                style={{ height: "unset" }}
              >
                <div
                  className="card p-3 text-center"
                  style={{ border: "2px solid purple", height: "100%" }}
                >
                  <h6>
                    <b>System Integration</b>
                  </h6>
                </div>
              </div>
            </div>
            <div className="row my-2">
              <div
                className="col-xs-12 my-2 col-sm-4 col-md-4"
                style={{ height: "unset" }}
              >
                <div
                  className="card p-3 text-center"
                  style={{ border: "2px solid purple", height: "100%" }}
                >
                  <h6>
                    <b>Actuarial Functions</b>
                  </h6>
                </div>
              </div>
              <div
                className="col-xs-12 my-2 col-sm-4 col-md-4"
                style={{ height: "unset" }}
              >
                <div
                  className="card p-3 text-center"
                  style={{ border: "2px solid purple", height: "100%" }}
                >
                  <h6>
                    <b>Legal and Compliance</b>
                  </h6>
                </div>
              </div>
              <div
                className="col-xs-12 my-2 col-sm-4 col-md-4"
                style={{ height: "unset" }}
              >
                <div
                  className="card p-3 text-center"
                  style={{ border: "2px solid purple", height: "100%" }}
                >
                  <h6>
                    <b>Finance Operations</b>
                  </h6>
                </div>
              </div>
            </div>
            <div className="row my-2">
              <div
                className="col-xs-12 my-2 col-sm-4 col-md-4"
                style={{ height: "unset" }}
              >
                <div
                  className="card p-3 text-center"
                  style={{ border: "2px solid purple", height: "100%" }}
                >
                  <h6>
                    <b>Claims Administration</b>
                  </h6>
                </div>
              </div>
              <div
                className="col-xs-12 my-2 col-sm-4 col-md-4"
                style={{ height: "unset" }}
              >
                <div
                  className="card p-3 text-center"
                  style={{ border: "2px solid purple", height: "100%" }}
                >
                  <h6>
                    <b>Underwriting & Policy Administration</b>
                  </h6>
                </div>
              </div>
              <div
                className="col-xs-12 my-2 col-sm-4 col-md-4"
                style={{ height: "unset" }}
              >
                <div
                  className="card p-3 text-center"
                  style={{ border: "2px solid purple", height: "100%" }}
                >
                  <h6>
                    <b>IT Systems Enablement</b>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-12 p-5 ">
          <Typography
            className="p-3"
            style={{
              backgroundColor: "purple",
              color: "white",
              fontSize: "21px",
            }}
          >
            Arella Value Chain-Roles & Responsibilities
          </Typography>
          <div className="card shadow p-5 text-center value_chain">
            <div className="items">
              <div className="item">
                <div className="heading">Partner</div>
                <div className="body">Name of Partner</div>
                <div className="bottom_line"></div>
                <div className="vertical_line"></div>
                <div className="description">Role</div>
              </div>
              <div className="item">
                <div className="heading">Product Development</div>
                <div className="body">
                  E.g.,Funeral,
                  <br />
                  Credit life,
                  <br />
                  Warranty and <br /> others
                </div>
                <div className="bottom_line"></div>
                <div className="vertical_line"></div>
                <div className="description">Arella</div>
              </div>
              <div className="item">
                <div className="heading">Sales & Marketing</div>
                <div className="body">
                  Channel: Intermediary,
                  <br />
                  Brokers,
                  <br />
                  Center,
                  <br />
                  Kiosk <br />
                  Fulfilment,
                  <br />
                  E-mail
                </div>
                <div className="bottom_line"></div>
                <div className="vertical_line"></div>
                <div className="description">Partner</div>
              </div>
              <div className="item">
                <div className="heading">Administration</div>
                <div className="body">
                  Online, Mobile<br></br>Application,
                  <br /> Call Center or <br />
                  Branch
                </div>
                <div className="bottom_line"></div>
                <div className="vertical_line"></div>
                <div className="description">Arella/Partner</div>
              </div>
              <div className="item">
                <div className="heading">Billing&Collection</div>
                <div className="body">
                  Debit Order &<br />
                  Point of Sale,
                  <br />
                  EFT on <br />
                  Accounts,
                  <br />
                  Vouchers
                </div>
                <div className="bottom_line"></div>
                <div className="vertical_line"></div>
                <div className="description">Arella/ Partner</div>
              </div>
              <div className="item">
                <div className="heading text-white">Claims</div>
                <div className="body">
                  Online, Mobile <br />
                  Application,
                  <br />
                  Call Center or <br />
                  Branches
                </div>
                <div className="bottom_line"></div>
                <div className="vertical_line"></div>
                <div className="description">Arella</div>
              </div>
              <div className="item">
                <div className="heading">Complaints</div>
                <div className="body">
                  Online,Mobile <br />
                  Application,
                  <br />
                  Call Center or <br />
                  Branches
                </div>
                <div className="bottom_line"></div>
                <div className="vertical_line"></div>
                <div className="description">Arella/Partner</div>
              </div>
            </div>
            <div className="bottom">
              <span>
                Arella Support Fuctions: Data Analytics, Marketing, Compliants &
                Business Intelligence
              </span>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
