import React from 'react'
import './LandingPage.scss'
import page1 from '../../../../Assets/page1.jpg'
import Carousel from 'react-bootstrap/Carousel'
import { Button } from '@material-ui/core'
import { useNavigate } from "react-router-dom";
import landingBck from '../../../../Assets/landingBck.png'
import landingvid from '../../../../Assets/landingvid.mp4'
import maize from '../../../../Assets/maize.svg'
import rain from '../../../../Assets/rain.svg'
import dove from '../../../../Assets/dove.svg'
import gsap from 'gsap'

export default function LandingPage() {
  let navigate = useNavigate();
 
  
  return (
    <Carousel fade interval={null}>
  <Carousel.Item >
    <div className='carousel-img'>
      <img
      className=" w-100"
     src={landingBck}
      alt="First slide" style={{height:'100%'}}
    />
    </div>
    <Carousel.Caption className='one'>
      <div className='one_layout'>
         <div className="body">
           <h3 >Looking for the Best Coverage - Unsure Where to Start? </h3>
           <p>Contact Arella today and be matched with an agent who <br/>
           will help identify the right coverage for you and your needs. <br/>
           Friendly service always - our customers are like family.</p>
           <Button variant='contained' onClick={()=>{
             navigate('/SignUpPolicy');
           }}>
           
             Get a Quote
           
           </Button>
         </div>

      </div>
     
      <div className="caption_button">
        
      </div>
     
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item >
    <div className='carousel-img'>
      
      
    </div>
    <Carousel.Caption className='two'>
      <div className='two_layout'>
       
        <div>
          <h3>Arella</h3>
          <p>We pride ourselves with extensive insurance knowledge in both the life and non-life insurance sectors which allows
us the confidence to customise innovative solutions that meet a partner’s or broker’s unique financial and strategic
objectives, while creating shared value for your customers across various industries.</p>
        </div>
      </div>
      </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
      <div className='three_layout'>
        <div className='first'>
          <div><img src={dove} style={{width:'8vw',height:'auto'}}/></div>
         <h4>KHOTSO</h4>
        </div>
       <div className='shadow_middle'>
          <div className='middle'>
           <div><img src={rain} style={{width:'8vw',height:'auto'}}/></div>
          <h4>PULA</h4>
        </div>
       </div>
        <div className='last'>
          <div><img src={maize} style={{width:'8vw',height:'auto'}}/></div>
         <h4>NALA</h4>
        </div>
      </div>
      <Carousel.Caption className='three'>
      <div className='three_caption'>
        <h4 style={{fontSize:'1.5rem'}}>
          Our products and benefits are tailored for the low-income markets both in
          the formal and informal sectors.
        </h4>
      </div>
      </Carousel.Caption>
  </Carousel.Item>
</Carousel>
  )
}
